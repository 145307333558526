import React, { useState, useEffect } from 'react';
import { Box, Keyboard, TextInput, Text, MaskedInput, Image, Button } from 'grommet'
import { View, Hide } from 'grommet-icons'

import firebase from 'firebase'

import { useHistory } from "react-router-dom";


const Login = () => {

    const history = useHistory();


    const [reveal, setReveal] = useState(false)
    const [errorLogin, setErrorLogin] = useState(false)
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")

    useEffect(() => {
        // console.log(JSON.parse(localStorage.getItem("user")))
        firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                // User is signed in.
                // console.log(user)
                // console.log(localStorage.getItem("user"))
                localStorage.getItem("user") !== null && history.replace('/admin/')
            } else {
                // No user is signed in.
                // console.log(user)
            }
        });
    }, [])


    const login = () => {
        // console.log(email)
        // console.log(password)
        firebase.auth().signInWithEmailAndPassword(email, password).then(re => {
            // console.log(re.user.uid)
            firebase.firestore().collection("users").doc(re.user.uid).get().then((doc) => {
                if (doc.exists) {
                    storeInfo(doc).then(() => history.replace('/admin/')
                    )
                } else {
                    // doc.data() will be undefined in this case
                    console.log("No such document!");
                }

            })

        }).catch((error) => {
            // Handle Errors here.
            // var errorCode = error.code;
            // var errorMessage = error.message;
            // console.log(errorMessage)
            setErrorLogin(true)
        });
    }

    const storeInfo = (doc) => {
        return Promise.resolve().then(()=> {
            localStorage.setItem("user", JSON.stringify(doc.data()))
        });
    }

    return (
        <Box fill pad="medium" justify="center" align="center">
            <Box gap="medium">
                <Box height="80px" width="medium">
                    <Image fit="contain" src={require("../assets/solviablack.png")} />
                </Box>
                {errorLogin && <Box animation="fadeIn" align="center" background="status-error" round="small" pad="small">
                    <Text color="white" >Usuario o contraseña incorrectos.</Text>
                </Box>}
                <Keyboard onEnter={login}>
                    <Box width="medium" gap="medium">
                        {/* <TextInput placeholder="Nombre" name="name" size="medium" type="text" value={name} onChange={event => setName(event.target.value)} /> */}
                        <MaskedInput
                            mask={[
                                {
                                    regexp: /^[\w\-_.]+$/,
                                    placeholder: "ejemplo"
                                },
                                { fixed: "@" },
                                {
                                    regexp: /^[\w]+$/,
                                    placeholder: "direccion"
                                },
                                { fixed: "." },
                                {
                                    regexp: /^[\w]+$/,
                                    placeholder: "com"
                                }
                            ]} name="email" size="medium" type="email" value={email} onChange={event => setEmail(event.target.value)} />
                        <Box width="medium" direction="row" align="center" round="4px" border>
                            <TextInput plain onChange={(ev) => setPassword(ev.target.value)} placeholder="Contraseña" type={reveal ? "text" : "password"} />
                            <Button
                                icon={reveal ? <View size="medium" /> : <Hide size="medium" />}
                                onClick={() => setReveal(!reveal)}
                            />
                        </Box>
                        <Button label="Acceder" onClick={login} size="medium" primary color="black" />
                    </Box>
                </Keyboard>
            </Box>
        </Box>
    );
}

export default Login;