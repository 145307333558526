import React, { useEffect, useState } from 'react';

import { useParams } from 'react-router-dom'

import firebase from 'firebase'
import Papa from 'papaparse'
import matchSorter from 'match-sorter'


import { Box, Layer, CheckBox, Heading, Text, Button } from 'grommet'
import { Send, Download, Trash } from 'grommet-icons';

import ReactTable from 'react-table'
import 'react-table/react-table.css'

const SubmissionsPending = () => {

    const [data, setData] = useState([])
    const [show, setShow] = useState(false)
    const [allEmails, setAllEmails] = useState([])
    const [checked, setChecked] = React.useState([]);
    const [surveyToDelete, setSurveyToDelete] = useState('')
    const id = useParams()

    useEffect(() => {
        console.log(id.id)
        firebase.firestore().collection("submissions").where("template", "==", id.id).where("completed", "==", false).onSnapshot((querySnapshot) => {
            console.log(querySnapshot.docs.filter((doc) => doc.data().sections[0].questions[0].answer !== "").map(doc => doc.data().user))
            // setData(querySnapshot.docs.filter((doc) => doc.data().sections[0].questions[0].answer === "").map(doc => ({ ...doc.data(), id: doc.id })))
            return (
                setData(querySnapshot.docs.map((doc) => {
                    return ({ ...doc.data(), id: doc.id })
                }))
            )
        })
        // getUsers()
        setAllEmails(data.map(row => row.user))
    }, [])


    const deleteSurvey = (surveyId) => {
        firebase.firestore().collection("submissions").doc(surveyId).delete().then(() => {
            console.log("Survey successfully deleted!");
            setShow(false)
            setSurveyToDelete('')
        }).catch((error) => {
            console.error("Error removing document: ", error);
            alert("Ha ocurrido un error, no se ha podido eliminar esta encuesta")
        });
    }


    const exportResults = () => {
        // console.log(data)
        // let headersQuestions = data[0].sections.flatMap((section) => section.questions.map((question) => question.question))
        // console.log(headersQuestions)
        let processData = data.map(user => {
            return ({
                "Email": user.user,
            })
        })
        let config = {
            quoteChar: '"',
            delimiter: ";",
            header: true,
            columns: ["Email"] //or array of strings
        }
        let csv = Papa.unparse(processData, config)

        let blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

        if (navigator.msSaveBlob) {
            navigator.msSaveBlob(blob, "Incompletos");
        } else {
            const link = document.createElement("a");
            if (link.download !== undefined) {
                const url = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", "Incompletos");
                link.style.visibility = "hidden";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }

    }


    const onCheck = (event, value) => {
        if (event.target.checked) {
            setChecked([...checked, value]);
        } else {
            setChecked(checked.filter(item => item !== value));
        }
    };

    const onCheckAll = event => setChecked(event.target.checked ? data.map(datum => datum.user) : []);

    // console.log(data && data.map(row => row.user))

    // console.log(data.map(row => row.user))

    return (
        <Box>
            <Box background="#f3f3f3" direction="row" align="center" pad="medium">
                <Box fill="horizontal">
                    <Heading level="2" margin="none">Encuestas Incompletas ({data.length})</Heading>
                </Box>
                <Box direction="row" fill="horizontal" justify="end" align="end" gap="small">
                    <Button color="black" reverse label="Descargar Todos" icon={<Download />} onClick={() => exportResults(checked)} />
                    <Button primary color="black" reverse label="Recordatorio a Todos" icon={<Send />} href={`mailto:?bcc=${(data.map(row => row.user)).toString()}&subject=Recuerda que tienes que completar el Cuestionario de Desempeño!`} />
                    {checked.length >= 1 && <Button primary color="black" reverse label="Recordatorio Seleccionados" icon={<Send />} href={`mailto:?bcc=${checked.toString()}&subject=Recuerda que tienes que completar el Cuestionario de Desempeño!`} />}
                </Box>
                {/* <Button label="Invitar" onClick={() => createInvite("maria.garciaalcaide@solvia.es")} /> */}
                {/* <Button label="Invitar a Todos" onClick={() => newInvites.map(invite => createInvite(invite))} /> */}
            </Box>
            <Box pad="medium">
                <ReactTable
                    data={data}
                    defaultPageSize={10}
                    columns={[
                        {
                            id: "checkbox",
                            width: 50,
                            accessor: datum => (
                                <Box fill justify="center" align="center">
                                    <CheckBox
                                        color="black"
                                        key={datum.id}
                                        checked={checked.indexOf(datum.user) !== -1}
                                        onChange={e => onCheck(e, datum.user)}
                                    /></Box>
                            ),
                            Header: (
                                <Box fill="horizontal" align="center"><CheckBox
                                    checked={checked.length === data.length}
                                    indeterminate={
                                        checked.length > 0 && checked.length < data.length
                                    }
                                    onChange={onCheckAll}
                                /></Box>
                            ),
                            sortable: false
                        }, {
                            Header: 'User',
                            accessor: 'user', // String-based value accessors!
                            style: { display: "flex", paddingLeft: 15, paddingRight: 15, alignItems: "center" },
                            filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ["user"] }),
                            filterAll: true,
                            filterable: true
                        },
                        {
                            Header: 'Manager',
                            accessor: 'manager', // String-based value accessors!
                            style: { display: "flex", paddingLeft: 15, paddingRight: 15, alignItems: "center" },
                        },
                        // {
                        //     Header: 'Time',
                        //     id: 'timestamp',
                        //     style: { display: "flex", paddingLeft: 15, paddingRight: 15, alignItems: "center" },
                        //     accessor: d => <Text>{d.timestamp && moment(d.timestamp.toDate()).format('MMMM Do YYYY, h:mm a')}</Text>
                        // }, 
                        // {
                        //     Header: 'Completed',
                        //     id: 'cop',
                        //     width: 100,
                        //     accessor: d => <Box fill={true} justify="center" align="center">{d.completed ? <StatusGood color="#308630" /> : <InProgress color="gainsboro" />}</Box>
                        // }, {
                        //     Header: 'Corrected',
                        //     id: 'corr',
                        //     width: 100,
                        //     accessor: d => <Box fill={true} justify="center" align="center">{d.corrected ? <StatusGood color="#308630" /> : <InProgress color="gainsboro" />}</Box>
                        // }, {
                        //     Header: "View",
                        //     id: "view",
                        //     width: 100,
                        //     accessor: d => <Box fill="horizontal" align="center"><Button icon={<View />}
                        //         onClick={() => history.push({
                        //             pathname: `/survey/${d.template}`,
                        //             search: `?user=${d.user}`,
                        //             hash: "#review"
                        //         })} /></Box>
                        // }, 
                        {
                            Header: "Enviar Recordatorio",
                            id: "reminder",
                            width: 150,
                            sortable: false,
                            accessor: d => <Box fill="horizontal" align="center"><Button icon={<Send />} href={`mailto:?bcc=${d.user}&subject=Recuerda que tienes que completar el Cuestionario de Desempeño!`} /></Box>
                        },
                        {
                            Header: "Eliminar Invitación",
                            id: "delete",
                            width: 150,
                            accessor: d => <Box fill="horizontal" align="center"> <Button icon={<Trash />} onClick={() => { setShow(true); setSurveyToDelete(d.id) }} /></Box>
                        }
                    ]}
                />
            </Box>
            {show && (
                <Layer
                    onEsc={() => setShow(false)}
                    onClickOutside={() => setShow(false)}
                >
                    <Box width="medium" gap="medium" pad="medium">
                        <Heading margin="none" level="3">Eliminar Respuesta</Heading>
                        <Text>Está seguro que desea eliminar esta respuesta a la encuesta? Esta acción no puede deshacerse</Text>
                        <Box direction="row" justify="between">
                            <Button label="Cerrar" onClick={() => setShow(false)} />
                            <Button primary label="Eliminar" color="status-critical" onClick={() => deleteSurvey(surveyToDelete)} />
                        </Box>
                    </Box>
                </Layer>
            )}
        </Box>
    )
};

export default SubmissionsPending;