import React, { useState } from 'react';

import { Box, Button, Collapsible, TextInput, Heading, Select } from 'grommet'

import { Descend, Ascend, Trash, Contract, Expand, Clone } from 'grommet-icons';

const EditorQuestion = ({ question, typeQuestion = "", index, first, last, typeQuestionCallback, editQuestionCallback, editAnswerCallback, addAnswerCallback, questionUpCallback, questionDownCallback, duplicateCallback, deleteAnswerCallback, deleteCallback }) => {

    const [questionOpen, setQuestionOpen] = useState(true)

    return (

        <Box round="small" background="#EDEDED" key={index} pad="medium">
            <Box direction="row">
                <Box fill="horizontal">
                    <TextInput placeholder="Pregunta" value={question.question} onChange={event => editQuestionCallback(event)} />
                </Box>
                <Box margin={{ right: "30px", left: "20px" }} direction="row">
                    <Button icon={<Clone />} onClick={() => duplicateCallback()} />
                    <Button icon={<Trash />} onClick={() => deleteCallback()} />
                    <Button disabled={first} icon={<Ascend />} onClick={() => questionDownCallback()} />
                    <Button disabled={last} icon={<Descend />} onClick={() => questionUpCallback()} />
                    <Button icon={questionOpen ? <Contract /> : <Expand />} onClick={() => setQuestionOpen(!questionOpen)} />
                </Box>
            </Box>


            <Collapsible direction="vertical" open={questionOpen}>

                <Box margin={{ top: "medium", bottom: "medium" }} direction="row" gap="medium">
                    <Box fill="horizontal">
                        <Heading level="3">Respuestas</Heading>
                    </Box>
                    <Select
                        options={['Paragraph', 'Multiple', 'Range']}
                        value={typeQuestion}
                        placeholder="Tipo de Pregunta"
                        onChange={({ option }) => typeQuestionCallback(option)}
                    />
                </Box>
                <Box margin={{ bottom: "50px" }}>

                    {typeQuestion === "Paragraph" && <Heading level="3">Tu respuesta larga</Heading>}

                    {(typeQuestion === "Multiple" || typeQuestion === "Range") && <Box gap="small">
                        {question.answers.map((answer, i) => {
                            return (
                                <Box key={i} direction="row" gap="small">
                                    <Box fill="horizontal">
                                        <TextInput placeholder="Respuesta" value={answer} onChange={event => editAnswerCallback(event, i)} />
                                    </Box>
                                    <Button disabled={question.answers.length === 1} icon={<Trash />} onClick={() => deleteAnswerCallback()} />
                                </Box>
                            )
                        })}
                        <Button label="Añadir Respuesta" onClick={() => addAnswerCallback()} />
                    </Box>}

                </Box>
            </Collapsible>
        </Box>

    );
}

export default EditorQuestion;