import React from 'react';
import './App.css';
import firebase from 'firebase/app'

import { Grommet } from 'grommet';

import Admin from './containers/admin'
import Survey from './containers/survey'
import SurveyCompleted from './containers/surveycompleted'
import Login from './containers/login';
import PrivateRoute from './components/PrivateRoute'

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";


const firebaseConfig = {
  apiKey: "AIzaSyCwK4slkQ-SWCiM62wNXjQO2I_j7db2anE",
  authDomain: "solviasurvey.firebaseapp.com",
  databaseURL: "https://solviasurvey.firebaseio.com",
  projectId: "solviasurvey",
  storageBucket: "solviasurvey.appspot.com",
  messagingSenderId: "754527153991",
  appId: "1:754527153991:web:ac82b6a2d862b231372662"
};

firebase.initializeApp(firebaseConfig)


const custom = {
  checkBox: {
    color: "black"
  },
  font: {
    // family: 'Poppins',
    size: '14px',
    height: '20px',
  },
  global:{
    colors: {
      brand: "black",
      focus:"gainsboro"
    }
  }
};

function App() {

  return (
    <Grommet 
    style={{ height: "100%" }} 
    themeMode="dark" theme={custom}>
      <Router>
        <Switch>
          <Route path="/login" component={() => <Login />} exact />
          <Route path="/survey/:id" component={() => <Survey />} />
          <Route path="/completed" component={() => <SurveyCompleted />} />
          <PrivateRoute path="/admin" component={() => <Admin />} />
          <Route path="/" exact><Redirect to="/survey/Pdk1EJNdIFxyNe9ddGmE" /></Route>
        </Switch>
      </Router>
    </Grommet>
  );
}

export default App;
