import React, { useState, useEffect } from 'react';
import { Box, Button, Collapsible, TextInput, TextArea, Heading } from 'grommet'
import { Descend, Ascend, Clone, Contract, Expand, Trash } from 'grommet-icons';

import { useParams, useHistory } from 'react-router-dom'

import EditorQuestion from '../../components/editorComponents/editorQuestion'


import firebase from 'firebase'

const initialize = {
    title: "",
    description: "",
    sections: []
}

const NewSurvey = () => {

    const [sectionOpen, setSectionOpen] = useState([true])
    const [originalData, setOriginalData] = useState(initialize)
    const [survey, setSurvey] = useState(initialize)
    const { id } = useParams();
    const history = useHistory();


    useEffect(() => {
        // console.log(id)
        id !== undefined ? initialData() : setSurvey(initialize)
    },[])

    const initialData = () => {
        firebase.firestore().collection("surveys").doc(id).get().then((doc) => {
            if (doc.exists) {
                // console.log("Document data:", doc.data());
                setOriginalData(doc.data());
                setSurvey(doc.data())
            } else {
                console.log("No such document!");
            }
        }).catch(function (error) {
            console.log("Error getting document:", error);
        });
    }


    const update = () => {
        firebase.firestore().collection("surveys").doc(id).set({
            title: survey.title,
            description: survey.description,
            sections: survey.sections
        }).then(
            // console.log('actualizado'),
            history.push('/admin/surveys')
        ).catch(
            err => console.log(err)
        );
    }


    const create = () => {
        firebase.firestore().collection("surveys").add({
            title: survey.title,
            description: survey.description,
            sections: survey.sections
        }).then(
            // console.log('hecho'),
            history.push('/admin/surveys')
        ).catch(
            err => console.log(err)
        );
    }

    const toogleSectionOpen = (i) => {
        var newarr = sectionOpen
        newarr[i] = !newarr[i]
        setSectionOpen([...newarr])
    }



    // SECTION

    const addSection = () => {
        var nextSection = survey.sections.length + 1
        var newarr = survey
        newarr.sections.push({
            title: `Sección ${nextSection}`,
            description: "",
            questions: [{
                question: "",
                answers: [""]
            }]
        })
        setSurvey({ ...newarr })
        setSectionOpen([...sectionOpen, true])
    }

    const deleteSection = (sectionIndex) => {
        var newarr = survey
        newarr.sections.splice(sectionIndex, 1)
        setSurvey({ ...newarr })
    }

    const editSection = (index, input, field) => {
        var newarr = survey
        newarr.sections[index][field] = input.target.value
        setSurvey({ ...newarr })
    }

    const duplicateSection = (sectionIndex) => {
        var newarr = survey
        var sectionToCopy = newarr.sections[sectionIndex]
        var copy = {
            ...sectionToCopy,
            title: `${sectionToCopy.title} copy`,
            questions: [...sectionToCopy.questions.map((question) => {
                return ({
                    question: question.question,
                    type: question.type,
                    answers: [...question.answers]
                })
            })]
        }
        newarr.sections = [...newarr.sections, copy]
        setSurvey({ ...newarr })
    }

    const reorderSection = (array, indexA, indexB) => {
        [array[indexA], array[indexB]] = [array[indexB], array[indexA]];
        var newarr = survey
        newarr.sections = array
        setSurvey({ ...survey })
    }


    // QUESTIONS

    const addQuestion = (sectionIndex) => {
        var newarr = survey
        newarr.sections[sectionIndex].questions.push({
            question: "",
            type:"Multiple",
            answers: [""]
        })
        setSurvey({ ...newarr })
    }

    const duplicateQuestion = (sectionIndex, questionIndex) => {
        var newarr = survey
        var questionToCopy = newarr.sections[sectionIndex].questions[questionIndex]
        var copy = { question: `${questionToCopy.question} copy`, type:questionToCopy.type, answers: [...questionToCopy.answers] }
        newarr.sections[sectionIndex].questions = [...newarr.sections[sectionIndex].questions, copy]

        setSurvey({ ...newarr })
    }

    const deleteQuestion = (sectionIndex, questionIndex) => {
        var newarr = survey
        newarr.sections[sectionIndex].questions.splice(questionIndex, 1)
        setSurvey({ ...newarr })
    }

    const editQuestion = (questionIndex, sectionIndex, input, field) => {

        var newarr = survey
        var newvalue = input
        newarr.sections[sectionIndex].questions[questionIndex][field] = newvalue
        if (newarr.sections[sectionIndex].questions[questionIndex].type === "Paragraph") {
            newarr.sections[sectionIndex].questions[questionIndex].answers = ["Opción 1"]
        }
        setSurvey({ ...newarr })
    }



    // ANSWERS 

    const addAnswer = async (questionIndex, sectionIndex) => {
        var newarr = survey
        var nextAnswer = newarr.sections[sectionIndex].questions[questionIndex].answers.length + 1
        newarr.sections[sectionIndex].questions[questionIndex].answers.push(`Opción ${nextAnswer}`)
        setSurvey({ ...newarr })
    }

    const editAnswer = (answerIndex, questionIndex, sectionIndex, input) => {
        var newarr = survey
        var newvalue = input.target.value
        newarr.sections[sectionIndex].questions[questionIndex].answers[answerIndex] = newvalue
        setSurvey({ ...newarr })
    }

    const deleteAnswer = (answerIndex, questionIndex, sectionIndex) => {
        var newarr = survey
        newarr.sections[sectionIndex].questions[questionIndex].answers.splice(answerIndex, 1)
        setSurvey({ ...newarr })
    }
 
    const reorderQuestion = async (array, indexA, indexB, indexSection) => {
        [array[indexA], array[indexB]] = [array[indexB], array[indexA]];
        var newarr = survey
        newarr.sections[indexSection].questions = array
        setSurvey({ ...survey })
    }


    return (
        <Box pad="medium" gap="medium">
            <TextInput placeholder="Título de la Encuesta" value={survey.title} onChange={e => setSurvey({ ...survey, title: e.target.value })} />
            <TextArea placeholder="Descripción de la Encuesta" value={survey.description} onChange={e => setSurvey({ ...survey, description: e.target.value })} />
            <Box gap="medium">
                {survey.sections.map((section, i) => {
                    var sectionIndex = i
                    return (
                        <Box key={i} >
                            <Box direction="row" gap="small" background="gainsboro" round="small">
                                <Box direction="row" pad={{horizontal:"small"}}>
                                    <Button icon={<Clone />} onClick={() => duplicateSection(i)} />
                                    <Button icon={<Trash />} onClick={() => deleteSection(i)} />
                                    <Button disabled={(survey.sections.length > 1) && (survey.sections.length !== i + 1) ? false : true} icon={<Descend />} onClick={() => reorderSection(survey.sections, i, i + 1)} />
                                    <Button disabled={(survey.sections.length > 1) && (i > 0) ? false : true} icon={<Ascend />} onClick={() => reorderSection(survey.sections, i, i - 1)} />
                                </Box>
                                <Box direction="row" fill="horizontal" justify="between" align="center" pad={{horizontal:"small"}} onClick={() => toogleSectionOpen(i)}>
                                    <h2>{section.title === "" ? `Sección ${i + 1}` : section.title}</h2>
                                    <Box margin="medium">
                                        {sectionOpen[i] ? <Contract /> : <Expand />}
                                    </Box>
                                </Box>
                            </Box>

                            <Collapsible direction="vertical" open={sectionOpen[i]}>

                                <Box background="#DADADA" pad="medium" margin="small" gap="medium">

                                    <TextInput placeholder="Titulo de la Sección" value={section.title} onChange={event => editSection(i, event, "title")} />
                                    <TextArea placeholder="Descripcion de la Sección" value={section.description} onChange={event => editSection(i, event, "description")} />

                                    <Box margin="small" gap="medium">
                                        <h3>Preguntas</h3>
                                        <Box gap="medium">
                                            {section.questions && section.questions.map((question, i) => {
                                                var questionIndex = i
                                                return (
                                                    <EditorQuestion
                                                        key={i}
                                                        question={question}
                                                        questionIndex={i}
                                                        typeQuestion={question.type}
                                                        first={(survey.sections[sectionIndex].questions.length > 1) && (i > 0) ? false : true}
                                                        last={(survey.sections[sectionIndex].questions.length > 1) && (survey.sections[sectionIndex].questions.length !== i + 1) ? false : true}
                                                        typeQuestionCallback={event => editQuestion(i, sectionIndex, event, "type")}
                                                        editQuestionCallback={event => editQuestion(i, sectionIndex, event.target.value, "question")}
                                                        editAnswerCallback={(event, answerIndex) => editAnswer(answerIndex, questionIndex, sectionIndex, event)}
                                                        deleteAnswerCallback={(answerIndex)=> deleteAnswer(answerIndex, questionIndex, sectionIndex)}
                                                        addAnswerCallback={() => addAnswer(i, sectionIndex)}
                                                        questionDownCallback={() => reorderQuestion(survey.sections[sectionIndex].questions, i, i - 1, sectionIndex)}
                                                        questionUpCallback={() => reorderQuestion(survey.sections[sectionIndex].questions, i, i + 1, sectionIndex)}
                                                        deleteCallback={() => { deleteQuestion(sectionIndex, i) }}
                                                        duplicateCallback={() => duplicateQuestion(sectionIndex, i)}
                                                    />
                                                )
                                            })}
                                        </Box>

                                        <Button label="Añadir Pregunta" onClick={() => addQuestion(i)} />
                                    </Box>
                                </Box>
                            </Collapsible>
                        </Box>
                    )
                })}
            </Box>
            <Box direction="row" justify="between">
                <Button label="Añadir Sección" onClick={addSection} />
                <Button disabled={ survey.title === "" ? true : false} label={id !== undefined ?  "Guardar" : "Crear"} onClick={id !== undefined ? update : create} primary />
            </Box>
        </Box>
    );
}

export default NewSurvey;