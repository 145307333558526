import React, { useEffect, useState } from 'react';
import { Box, Image, Anchor } from 'grommet';

import { User, Tasks, Database, Cube, Logout } from 'grommet-icons'

import firebase from 'firebase'

import Users from './admin/users'
import Surveys from './admin/surveys'
import NewSurvey from './admin/newsurvey';
import Manager from './admin/manager';
import Submissions from './admin/submissions'
import Uploads from './admin/uploads'
import Results from './admin/results'
import TeamResults from './admin/teamresults'

import {
    BrowserRouter as Router,
    Switch,
    Route,
    useHistory,
    useLocation
} from "react-router-dom";
import SubmissionsPending from './admin/submissionsPending';
import SubmissionsOnProcess from './admin/submissionsOnProcess';

const Admin = () => {

    const history = useHistory();
    const location = useLocation();
    const [userInfo, setUserInfo] = useState({})
    const [userRole, setUserRole] = useState({})

    // console.log(location.pathname)

    useEffect(() => {
        firebase.auth().onAuthStateChanged((user) =>  {
            if (user) {
                // User is signed in.
                // console.log(user)
                let userInfo = JSON.parse(localStorage.getItem("user"))
                setUserInfo({ email: user.email, uid: user.uid })
                setUserRole(userInfo.roles)
                userInfo.roles.manager && !userInfo.roles.superadmin && history.replace(`/admin/manager/${user.email}?survey=Pdk1EJNdIFxyNe9ddGmE`)
            } else {
                // No user is signed in.
                // console.log(user)
                history.replace('/login')
            }
        });
    }, [])

    const out = () => {
        firebase.auth().signOut().then(() => {
            // Sign-out successful.
            localStorage.removeItem("user")
            history.replace('/login')
        }).catch(function (error) {
            // An error happened.
            alert(error.message)
        });
    }

    return (
        <Box>
            <Box height="80px" align="center" pad={{ horizontal: "medium" }} background="black" direction="row" justify="between">
                <Box height="75px" width="200px">
                    <Image fit="contain" src={require("../assets/solviawhite.png")} />
                </Box>
                <Box margin={{ top: "10px" }} direction="row" gap="medium">
                    {userRole.superadmin && <Anchor icon={<Tasks />} disabled={location.pathname.includes("/admin/surveys")} color="white" onClick={() => history.push("/admin/surveys")} label="Encuestas" />}
                    {userRole.superadmin && <Anchor icon={<User />} disabled={location.pathname.includes("/admin/users")} color="white" onClick={() => history.push("/admin/users")} label="Usuarios" />}
                    {/* {userRole.superadmin && <Anchor icon={<Upload />} disabled={location.pathname.includes("/admin/upload")} color="white" onClick={() => history.push("/admin/upload")} label="Upload" />} */}
                    {userRole.superadmin && <Anchor icon={<Database />} disabled={location.pathname.includes("/admin/results")} color="white" onClick={() => history.push({ pathname: `/admin/results/Pdk1EJNdIFxyNe9ddGmE` })} label="Resultados" />}
                    {userRole.manager && <Anchor icon={<Cube />} disabled={location.pathname.includes("/admin/manager")} color="white" onClick={() => history.push({ pathname: `/admin/manager/${userInfo.email}`, search: `?survey=Pdk1EJNdIFxyNe9ddGmE` })} label="Mi Equipo" />}
                    <Anchor primary color="white" label="Salir" icon={<Logout />} onClick={() => out()} />
                </Box>
            </Box>
            <Switch>
                <Route path="/admin/surveys" render={() => <Surveys />} />
                <Route path="/admin/newsurvey" render={() => <NewSurvey />} />
                <Route path="/admin/edit/:id" render={() => <NewSurvey />} />
                <Route path="/admin/submissions/:id" render={() => <Submissions />} />
                <Route path="/admin/submissions-pending/:id" render={() => <SubmissionsPending />} />
                <Route path="/admin/submissions-on-process/:id" render={() => <SubmissionsOnProcess />} />
                <Route path="/admin/manager/" render={() => <Manager />} />
                <Route exact path="/admin/manager/:id" render={() => <Manager />} />
                <Route path="/admin/users" render={() => <Users />} />
                <Route path="/admin/upload" render={() => <Uploads />} />
                <Route exact path="/admin/results/:id" render={() => <Results />} />
                <Route exact path="/admin/results/:id/:id" render={() => <TeamResults />} />
            </Switch>
        </Box>
    )
};

export default Admin;