import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom'

import firebase from 'firebase'

import { Box, Text, Button, Heading, Layer, Select } from 'grommet'
import { Trash, Edit, Group, Send, Close, InProgress, BarChart, Task, View, Duplicate, AddCircle, Compare } from 'grommet-icons';

import ReactTable from 'react-table'
import 'react-table/react-table.css'



const Surveys = () => {

    const [data, setData] = useState([])
    const [users, setUsers] = useState([])
    const [usersToFilter, setUsersToFilter] = useState([])
    const [usersSelected, setUsersSelected] = useState([])
    const [show, setShow] = useState(false)
    const [surveySelected, setSurveySelected] = useState('')
    const history = useHistory();

    useEffect(() => {
        firebase.firestore().collection("surveys").onSnapshot((querySnapshot) => {
            return (
                setData(querySnapshot.docs.map((doc) => {
                    return ({ ...doc.data(), id: doc.id })
                }))

            )
        })
    }, [])


    const createInvite = (user) => {
        console.log(user)
        firebase.firestore().collection("surveys").doc(surveySelected).get().then((doc) => {
            if (doc.exists) {
                console.log("Good")
                const answersData = doc.data().sections.map((section) => {
                    return ({
                        title: section.title,
                        questions: section.questions.map((question, i) => {
                            return ({
                                answers: question.answers,
                                question: question.question,
                                answer: "",
                                order: i
                            })
                        })
                    })
                })

                const surveySubmission = {
                    user: user,
                    timestamp: firebase.firestore.FieldValue.serverTimestamp(),
                    sections: answersData,
                    completed: false,
                    corrected: false,
                    template: surveySelected,
                    fromManager: false,
                    // manager: manager
                }

                firebase.firestore().collection("submissions").add(surveySubmission).then((docRef) => {
                    console.log(docRef)
                    console.log("Double Good")
                    setShow(false)
                }).catch(
                    err => {
                        console.log(err)
                        setShow(false)
                        alert("Ha ocurrido un error y no se ha podido invitar a los usuarios usuarios, inténtelo más tarde")
                    }
                );

            } else {
                console.log("No such document!");
                setShow(false)
            alert("Ha ocurrido un error y no se ha podido invitar a los usuarios usuarios, inténtelo más tarde")
            }
        }).catch(function (error) {
            console.log("Error getting document:", error);
            setShow(false)
            alert("Ha ocurrido un error y no se ha podido invitar a los usuarios usuarios, inténtelo más tarde")
        });
    }


    const getUsers = () => {
        firebase.firestore().collection("users").get()
            .then((querySnapshot) => {
                setUsers(querySnapshot.docs.map((user, i) => {
                    return {
                        "lab": user.data().email,
                        "val": i
                    }
                }))
                setUsersToFilter(querySnapshot.docs.map((user, i) => {
                    return {
                        "lab": user.data().email,
                        "val": i
                    }
                }))
            })
            .catch(function (error) {
                console.log("Error getting documents: ", error);
            });


    }



    const changethatpass = ()=> {
    }

    const duplicateSurvey = async (id) => {
        firebase.firestore().collection("surveys").doc(id).get().then((doc) => {
            if (doc.exists) {
                // console.log("Document data:", doc.data());
                var data = doc.data()
                firebase.firestore().collection("surveys").add({
                    ...data,
                    title: `${data.title} copy`
                }).then(
                    console.log('hecho')
                ).catch(
                    err => console.log(err)
                );
            } else {
                console.log("No such document!");
            }
        }).catch(function (error) {
            console.log("Error getting document:", error);
        });
    }

    const deleteSurvey = (id) => {
        firebase.firestore().collection("surveys").doc(id).delete().then(() => {
            console.log("Document successfully deleted!");
        }).catch((error) => {
            console.error("Error removing document: ", error);
        });
    }


    return (
        <Box>
            <Box background="#f3f3f3" direction="row" align="center" justify="between" pad="medium">
                <Heading level="2" margin="none">Encuestas</Heading>
                <Box>
                    <Button primary color="black" reverse label="Nueva Encuesta" icon={<AddCircle />} onClick={() => history.push('/admin/newsurvey')} />
                </Box>
                {/* <Box>
                    <Button color="black" label="memem" onClick={()=> changethatpass()}/>
                </Box> */}
            </Box>
            <Box pad="medium">
                <ReactTable
                    data={data}
                    defaultPageSize={10}
                    columns={[
                        {
                            Header: 'Título',
                            accessor: 'title', // String-based value accessors!
                            style: { display: "flex", paddingLeft: 15, paddingRight: 15, alignItems: "center" },
                        },
                        // {
                        //     Header: 'Secciones',
                        //     id: "sections",
                        //     style: { display: "flex", justifyContent: "center", paddingLeft: 15, paddingRight: 15, alignItems: "center" },
                        //     accessor: d => <Text>{d.sections.length}</Text>,
                        // }, {
                        //     Header: 'Preguntas',
                        //     id: "questions",
                        //     style: { display: "flex", justifyContent: "center", paddingLeft: 15, paddingRight: 15, alignItems: "center" },
                        //     accessor: d => {
                        //         var countQuestions = 0
                        //         d.sections.map((section) => {
                        //             countQuestions = section.questions.length + countQuestions
                        //         })
                        //         return (
                        //             <Text>{countQuestions}</Text>
                        //         )
                        //     }
                        // }, 
                        {
                            Header: "Invitar",
                            id: "invite",
                            width: 100,
                            accessor: d => <Box fill="horizontal" align="center"><Button icon={<Group />} onClick={() => { setSurveySelected(d.id); getUsers(); setShow(true) }} /></Box>
                        }, {
                            Header: "Resultados",
                            id: "results",
                            width: 100,
                            accessor: d => <Box fill="horizontal" align="center"><Button icon={<BarChart />} onClick={() => history.push(`/admin/results/${d.id}`)} /></Box>
                        }, {
                            Header: "Completas",
                            id: "submissions",
                            width: 100,
                            accessor: d => <Box fill="horizontal" align="center"><Button icon={<Task />} onClick={() => history.push(`/admin/submissions/${d.id}`)} /></Box>
                        }, {
                            Header: "Incompletas",
                            id: "pending",
                            width: 120,
                            accessor: d => <Box fill="horizontal" align="center"><Button icon={<Compare />} onClick={() => history.push(`/admin/submissions-on-process/${d.id}`)} /></Box>
                        }, {
                            Header: "Sin Comenzar",
                            id: "nostarted",
                            width: 120,
                            accessor: d => <Box fill="horizontal" align="center"><Button icon={<InProgress />} onClick={() => history.push(`/admin/submissions-pending/${d.id}`)} /></Box>
                        }, {
                            Header: "Preview",
                            id: "view",
                            width: 100,
                            accessor: d => <Box fill="horizontal" align="center"><Button icon={<View />} onClick={() => history.push(`/survey/${d.id}`)} /></Box>
                        }, {
                            Header: "Editar",
                            id: "edit",
                            width: 100,
                            accessor: d => <Box fill="horizontal" align="center"><Button icon={<Edit />} onClick={() => history.push(`/admin/edit/${d.id}`)} /></Box>
                        }, {
                            Header: "Duplicar",
                            id: "duplicate",
                            width: 100,
                            accessor: d => <Box fill="horizontal" align="center"><Button icon={<Duplicate />} onClick={() => duplicateSurvey(d.id)} /></Box>
                        }, {
                            Header: "Eliminar",
                            id: "delete",
                            width: 100,
                            accessor: d => <Box fill="horizontal" align="center"><Button disabled icon={<Trash />} onClick={() => deleteSurvey(d.id)} /></Box>
                        }
                    ]}
                />
            </Box>
            {show && (
                <Layer
                    onEsc={() => setShow(false)}
                    onClickOutside={() => setShow(false)}
                >
                    <Box width="large" gap="medium" pad="medium">
                        <Heading margin="none" level="3">Invitar Usuarios</Heading>
                        <Text>Selecciona a los usuarios que quieres invitar a la encuesta</Text>

                        <Select
                            size="medium"
                            placeholder="Select"
                            multiple
                            closeOnChange={false}
                            disabledKey="dis"
                            labelKey="lab"
                            valueKey="val"
                            value={usersSelected}
                            options={usersToFilter}
                            onChange={({ value: nextValue }) => setUsersSelected(nextValue)}
                            onClose={() => setUsersToFilter(users)}
                            onSearch={text => {
                                // The line below escapes regular expression special characters:
                                // [ \ ^ $ . | ? * + ( )
                                const escapedText = text.replace(/[-\\^$*+?.()|[\]{}]/g, "\\$&");

                                // Create the regular expression with modified value which
                                // handles escaping special characters. Without escaping special
                                // characters, errors will appear in the console
                                const exp = new RegExp(escapedText, "i");
                                setUsersToFilter(users.filter(o => exp.test(o.lab)));
                            }}
                        />
                        <Box overflow="scroll" pad="small" border height="small" gap="small" wrap direction="row-responsive">
                            {usersSelected.map((user, i) => {
                                return <Box key={i} margin={{ vertical: "5px" }}><Button size="xsmall" label={user.lab} onClick={() => setUsersSelected(usersSelected.filter(net => net.lab !== user.lab))} reverse icon={<Close />} primary color="black" /></Box>
                            })}
                        </Box>
                        <Box direction="row" justify="between">
                            <Button label="Cancelar" onClick={() => setShow(false)} />
                            <Box direction="row" gap="small">
                                {/* <Button primary label="Invitar Seleccionados" color="black" onClick={() => { setShow(false) }} />
                                <Button primary label="Invitar a todos" color="black" onClick={() => { setShow(false) }} /> */}
                                {/* <Button primary color="black" reverse label="Recordatorio a Todos" icon={<Send />} href={`mailto:?bcc=${(data.map(row => row.user)).toString()}&subject=Recuerda que tienes que completar la encuesta de evaluación!`} /> */}
                                {usersSelected.length >= 1 && <Button primary color="black" reverse label="Invitar Seleccionados" onClick={() => usersSelected.map(user => createInvite(user.lab))} icon={<Send />} href={`mailto:?bcc=${usersSelected.map(user => user.lab).toString()}&subject=Recuerda que tienes que completar la Evaluación de Desempeño!`} />}
                            </Box>
                        </Box>
                    </Box>
                </Layer>
            )}
        </Box>
    );
}

export default Surveys;