import React from 'react';
import Question from './question'

import { Box, Heading } from 'grommet'

const Section = ({ review , section = {}, sectionAnswered = {}, sectionCallback }) => {


    const respondQuestion = (questionIndex, value) => {
        var newarr = sectionAnswered
        newarr.questions[questionIndex].answer = value
        sectionCallback([...newarr.questions])
    }



    return (
        <Box fill="horizontal" gap="medium" >
            <Box border={{
                "color": "black",
                "size": "large",
                "style": "solid",
                "side": "left"
            }} fill="horizontal" round="small" pad="large" gap="medium" background="white" elevation="medium">
                <Heading level="3" style={{ width: "100%", maxWidth: "100%" }} margin="none">{section.title}</Heading>
                <Heading level="4" style={{ width: "100%", maxWidth: "100%" }} margin="none">{section.description}</Heading>
            </Box>
            {section.questions && section.questions.map((question, i) => {
                return (
                    <Box key={i} round="small" pad="large" background="white" elevation="medium">
                        <Question
                            disabled={review}
                            question={question}
                            questionAnswered={sectionAnswered.questions[i].answer}
                            answerCallback={cb => respondQuestion(i, cb)}
                        />
                    </Box>
                )
            })}
        </Box>
    );
}

export default Section;