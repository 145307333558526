import React, { useState, useEffect } from 'react';

import { Box, Heading, Button, TextArea } from 'grommet'



const Question = ({ disabled, question, questionAnswered, answerCallback }) => {

    const [answerSelected, setAnswerSelected] = useState(undefined)
    const [answerResponded, setAnswerResponded] = useState("")

    useEffect(() => {
        setAnswerSelected(questionAnswered)
        setAnswerResponded(questionAnswered)
    }, [])

    const clickAnswer = (i) => {
        setAnswerSelected(i)
        answerCallback(i)
    }

    const writeAnswer = (event) => {
        setAnswerResponded(event)
        answerCallback(event)
    }

    return (
        <Box>
            <Box margin={{ bottom: "30px" }}>
                <Heading margin="none" style={{ width: "100%", maxWidth: "100%" }} textAlign="center" level="4">{question.question}</Heading>
            </Box>
            {question.type === "Paragraph" &&
                <Box>
                    <TextArea disabled={disabled} value={answerResponded} onChange={(event) => writeAnswer(event.target.value)} />
                </Box>
            }

            {(question.type === "Multiple" || question.type === "Range") &&
                <Box wrap gap="small" justify="center" direction={question.type === "Range" ? "row" : "column"}>
                    {question.answers.map((answer, i) => {
                        return (
                            <Box key={i} margin={{ vertical: "small" }}>
                                <Button
                                    disabled={disabled}
                                    color="black"
                                    label={answer}
                                    primary={i === answerSelected ? true : false}
                                    onClick={() => clickAnswer(i)}
                                />
                            </Box>
                        )
                    })}
                </Box>
            }
        </Box>
    );
}

export default Question;