import React from 'react';
import {
    BrowserRouter as Router,
    Route,
    useHistory,
    useLocation,
    Redirect
} from "react-router-dom";




const PrivateRoute = ({ component: Component, ...rest }) => {
    
    const location = useLocation();
    let user = JSON.parse(localStorage.getItem("user"))

    if (user === null) {
        return <Redirect to="/login" />
    }else if (user.roles === null) {
        return <Redirect to="/login" />
    } else if (!user.roles.superadmin && !location.pathname.includes(`/admin/manager/${user.email}`)){
        return <Redirect to={`/admin/manager/${user.email}`} />
    }else if (user.roles.superadmin) {
        return <Route {...rest} render={ props => <Component {...props} />} />
    } else if (user.roles.manager) {
        return <Route {...rest} render={ props => <Component {...props} />} />
    } else {
        return <Redirect to="/login" />
    }
}

 export default PrivateRoute