import React, { useState, useEffect } from 'react';

import { useHistory, useParams, useLocation } from 'react-router-dom'

import firebase from 'firebase'

import { Box, Heading, Button} from "grommet"
import { View } from 'grommet-icons';

import ReactTable from 'react-table'
import 'react-table/react-table.css'


const Results = () => {

    const [data, setData] = useState([])
    const [survey, setSurvey] = useState([])
    const [viewData, setViewData] = useState("Todos")
    const [sectionOpen, setSectionOpen] = useState([true])
    const history = useHistory();
    const id = useParams()
    const search = useLocation().search


    useEffect(() => {
        // console.log(id.id)
        loadInitialData()
    }, [])

    const loadInitialData = () => {
        firebase.firestore().collection("surveys").doc(id.id).get().then((doc) => {
            if (doc.exists) {
                console.log("Document data:", doc.data());
                setSurvey(doc.data())
            } else {
                // doc.data() will be undefined in this case
                console.log("No such document!");
            }
        }).catch(function (error) {
            console.log("Error getting document:", error);
        })

        firebase.firestore().collection("users").where("isManager", "==", true).get().then((querySnapshot) => {
            if (querySnapshot.docs.length === 0) {
                setData([])
                console.log("Nada :(")
            } else {
                var docs = querySnapshot.docs.map((doc) => {
                    return ({
                        ...doc.data(),
                    })
                })
                setData(docs)
                console.log(docs)
            }
        }).catch((error) => {
            console.log("Error getting document:", error);
        });
    }




    return (
        <Box>
            <Box background="#f3f3f3" pad="medium" direction="row" justify="between">
                <Box >
                    <Heading style={{maxWidth: '100%'}} level="3" margin="none">Resultados de {survey.title} por equipos</Heading>
                </Box>
                <Box justify="end">
                    <Button label="Ver Resultados Agrupados" icon={<View />} onClick={() => history.push(`/admin/results/${id.id}/all`)} />
                </Box>
            </Box>
            <Box pad="medium">
                <ReactTable
                    data={data}
                    defaultPageSize={10}
                    columns={[
                        {
                            Header: 'Equipo',
                            style: { display: "flex", paddingLeft: 15, paddingRight: 15, alignItems: "center" },
                            accessor: 'team' // String-based value accessors!
                        },{
                            Header: 'Manager',
                            style: { display: "flex", paddingLeft: 15, paddingRight: 15, alignItems: "center" },
                            accessor: "username",
                        }, {
                            Header: 'Localización',
                            style: { display: "flex", paddingLeft: 15, paddingRight: 15, alignItems: "center" },
                            accessor: 'location'
                        },  
                        {
                            Header: 'Resultados por Equipo',
                            id: "results",
                            width: 200,
                            accessor: d => <Box fill="horizontal" align="center"><Button icon={<View />} onClick={() => history.push(`/admin/results/${id.id}/${d.email}`)} /></Box>
                        }
                    ]}
                />
            </Box>
        </Box>
    );
}

export default Results;