import React, { useEffect, useState } from 'react';

// import { useHistory, useParams, useLocation } from 'react-router-dom'

import firebase from 'firebase'

import { Box, Heading, Button, Layer, Text, Select } from 'grommet'
import {AddCircle, Upload } from 'grommet-icons';


import Papa from 'papaparse'

import ReactTable from 'react-table'
import 'react-table/react-table.css'


const Uploads = () => {


    // const [data, setData] = useState([])
    const [users, setUsers] = useState([])
    const [surveys, setSurveys] = useState([])
    const [show, setShow] = useState(false)
    const [surveyToInvite, setSurveyToInvite] = useState({lab: "Ninguna", val:0})
    // const history = useHistory();
    // const id = useParams()
    // const search = useLocation().search


    useEffect(() => {
        firebase.firestore().collection("surveys").onSnapshot((querySnapshot) => {
            return (
                setSurveys(querySnapshot.docs.map((doc) => {
                    return ({ lab: doc.data().title, val: doc.id })
                }))

            )
        })
    }, [])

    const toTitleCase = (phrase) => {
        return phrase
            .toLowerCase()
            .split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    };



    // const createInvite = (user) => {
    //     console.log(user)
    //     firebase.firestore().collection("surveys").doc("Pdk1EJNdIFxyNe9ddGmE").get().then((doc) => {
    //         if (doc.exists) {
    //             console.log("Good")
    //             const answersData = doc.data().sections.map((section) => {
    //                 return ({
    //                     title: section.title,
    //                     questions: section.questions.map((question, i) => {
    //                         return ({
    //                             answers: question.answers,
    //                             question: question.question,
    //                             answer: "",
    //                             order: i
    //                         })
    //                     })
    //                 })
    //             })

    //             const surveySubmission = {
    //                 user: user,
    //                 timestamp: firebase.firestore.FieldValue.serverTimestamp(),
    //                 sections: answersData,
    //                 completed: false,
    //                 corrected: false,
    //                 template: "Pdk1EJNdIFxyNe9ddGmE",
    //                 fromManager: false,
    //                 // manager: manager
    //             }

    //             firebase.firestore().collection("submissions").add(surveySubmission).then((docRef) => {
    //                 console.log(docRef)
    //                 console.log("Double Good")
    //                 setShow(false)
    //             }).catch(
    //                 err => {
    //                     console.log(err)
    //                     alert("Ha ocurrido un error y no se ha podido invitar a los usuarios usuarios, inténtelo más tarde")
    //                 }
    //             );

    //         } else {
    //             console.log("No such document!");
    //             alert("Ha ocurrido un error y no se ha podido invitar a los usuarios usuarios, inténtelo más tarde")
    //         }
    //     }).catch(function (error) {
    //         console.log("Error getting document:", error);
    //         alert("Ha ocurrido un error y no se ha podido invitar a los usuarios usuarios, inténtelo más tarde")
    //     });
    // }

    const handleChange = (file) => {
        // console.log(file[0])

        Papa.parse(file[0], {
            header: true,
            complete: async (results) => {
                await setUsers(results.data.map((user) => {
                    return ({
                        email: user.Email,
                        username: user.Name ? toTitleCase((user.Name.replace(/^[^,]+, */, '') + " " + user.Name.split(',')[0]).toLowerCase()) : "",
                        manager: user.ManagerEmail,
                        unit: user.Unit,
                        team: user.Team,
                        managername: user.ManagerName ? toTitleCase((user.ManagerName.replace(/^[^,]+, */, '') + " " + user.ManagerName.split(',')[0]).toLowerCase()) : "",
                        location: user.Location,
                    })
                }))
                await console.log(results.data.map((user) => {
                    return ({
                        email: user.Email,
                        username: user.Name ? toTitleCase((user.Name.replace(/^[^,]+, */, '') + " " + user.Name.split(',')[0]).toLowerCase()) : "",
                        manager: user.ManagerEmail,
                        unit: user.Unit,
                        team: user.Team,
                        managername: user.ManagerName ? toTitleCase((user.ManagerName.replace(/^[^,]+, */, '') + " " + user.ManagerName.split(',')[0]).toLowerCase()) : "",
                        location: user.Location
                    })
                }));
            }
        });
    }

    const isAdmin = (user) => {
        // console.log(users.find((u) => user.email === u.manager))
        let manager = users.find((u) => user.email === u.manager)

        if (manager !== undefined) {
            return true
        } else {
            return false
        }

    }

    const createUsers = () => {
        users.map(async (user) => {
            let isManager = await isAdmin(user)
            // console.log(`https://us-central1-solviasurvey.cloudfunctions.net/createUser?email=${user.email}&username=${user.name}&isManager=${isManager}&manager=${user.manager}`)

            // console.log({ ...user, isManager: isManager })
            // fetch(`https://us-central1-solviasurvey.cloudfunctions.net/createUser?email=${user.email}&username=${user.name}&isManager=${isManager}&manager=${user.manager}`).then(
                
            fetch(`https://europe-west1-solviasurvey.cloudfunctions.net/createUser`, {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ ...user, isManager: isManager, roles: { superadmin: false, manager: isManager } })
            }).then((response) => {
                console.log(response.json())
                // createInvite(user.email)
            }).catch(err => {
                console.log(err);
                alert("Ha ocurrido un error intentando cargar el usuario " + user.email)
            })
        })
    }

    return (
        <Box>
            <Box background="#f3f3f3" direction="row" align="center" justify="between" pad="medium">
                <Heading margin="none" level="2">Cargar Usuarios Masivo</Heading>
                <Box direction="row" gap="small">
                    <input type="file" onChange={(e) => handleChange(e.target.files)} />
                    {users.length >= 1 && <Button color="black" reverse label="Invitar a Encuesta" icon={<AddCircle />} onClick={() => setShow(true)} />}
                    {users.length >= 1 && <Button primary color="black" reverse label="Cargar Usuarios" icon={<Upload />} onClick={createUsers} />}
                </Box>
            </Box>
            <Box pad="medium">
                {/* <Button label="Es Admin" onClick={() => isAdmin(users[18])} /> */}
                <ReactTable
                    data={users}
                    defaultPageSize={10}
                    columns={[
                        {
                            Header: 'User',
                            accessor: 'username' // String-based value accessors!
                        }, {
                            Header: 'Email',
                            accessor: 'email'
                        }
                    ]}
                />

                {show && (
                    <Layer
                        onEsc={() => setShow(false)}
                        onClickOutside={() => setShow(false)}
                    >
                        <Box width="large" gap="medium" pad="medium">
                            <Heading margin="none" level="3">Invitar usuarios a una encuesta</Heading>
                            <Text>Selecciona la encuesta a la que quieres invitar a los usuarios que se van a subir</Text>

                            <Select
                                size="medium"
                                placeholder="Selecciona una encuesta"
                                disabledKey="dis"
                                labelKey="lab"
                                valueKey="val"
                                value={surveyToInvite}
                                options={surveys}
                                onChange={(op) => {setSurveyToInvite(op.value);console.log(op)}}
                            />
                            <Box direction="row" justify="between">
                                <Button label="Cancelar" onClick={() => setShow(false)} />
                                <Box direction="row" gap="small">
                                    {/* <Button primary label="Invitar Seleccionados" color="black" onClick={() => { setShow(false) }} />
                                <Button primary label="Invitar a todos" color="black" onClick={() => { setShow(false) }} /> */}
                                    {/* <Button primary color="black" reverse label="Recordatorio a Todos" icon={<Send />} href={`mailto:?bcc=${(data.map(row => row.user)).toString()}&subject=Recuerda que tienes que completar la encuesta de evaluación!`} /> */}
                                    <Button label="Crear Usuarios e invitar" onClick={createUsers} />
                                </Box>
                            </Box>
                        </Box>
                    </Layer>
                )}
            </Box>
        </Box>
    );
}

export default Uploads;