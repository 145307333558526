import React, { useState, useEffect, useRef } from 'react';

import { useHistory, useParams, useLocation } from 'react-router-dom'

import { firestore } from 'firebase'

import Loader from 'react-loader-spinner'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"


import Section from "../components/section"
import { Button, Box, Heading, Image, Layer, Text, TextInput } from 'grommet';



const Survey = () => {

    const [loading, setLoading] = useState(true)
    const [data, setData] = useState([])
    const [user, setUser] = useState("")
    const [currentSection, setCurrentSection] = useState(0)
    const [animation, setAnimation] = useState("fadeIn")
    const [newSurvey, setNewSurvey] = useState(true)
    const [userReady, setUserReady] = useState(false)
    const [answersData, setAnswersData] = useState([])
    const [idResponse, setIdResponse] = useState(null)
    const [manager, setManager] = useState("")
    const [wrongSurvey, setWrongSurvey] = useState(false)
    const [fromManager, setFromManager] = useState(false)
    const [review, setReview] = useState(false)
    const [showComplete, setShowComplete] = useState(false)
    const [allChecked, setAllChecked] = useState(undefined)
    const [submission, setSubmission] = useState("")

    const { id } = useParams();
    const history = useHistory();
    const hash = useLocation().hash
    const search = useLocation().search


    const timerRef = useRef(loading);
    timerRef.current = loading



    useEffect(() => {

        initialData()
        hash === "#review" && setReview(true)

        if (search.includes("submission")) {
            var us = search.substring(0, search.indexOf("&")).replace('?user=', '')
            console.log(us)
            setUser(us)

            var sub = search.split('&submission=')[1]
            console.log(sub)
            setSubmission(sub)

            checkSurvey(sub)

        } else {
            if (hash === "#review") {
                setReview(true)
            } if (hash === "#reviewfrommanager") {
                setReview(true)
                setFromManager(true)
                setUser(search.replace('?user=', ''))
            } if (hash === "#frommanager") {
                setFromManager(true)
                setUser(search.replace('?user=', ''))
            }
            search !== "" && setUser(search.replace('?user=', ''))
            search !== "" && hash !== "#frommanager" && hash !== "#reviewfrommanager" && checkUserEmail(search.replace('?user=', ''))
        }

    }, [])

    useEffect(() => {
        var timer

        if (timerRef.current) {
            timer = setTimeout(() => {
                timerRef.current && setWrongSurvey(true)
            }, 6000)
        }

        if (!timerRef.current) {
            clearTimeout(timer);
        }

    }, [loading])


    const initialData = () => {
        firestore().collection("surveys").doc(id).get().then((doc) => {
            if (doc.exists) {
                // console.log("Document data:", doc.data());
                setData(doc.data())
                setLoading(false)
                setAnswersData(
                    doc.data().sections.map((section) => {
                        return ({
                            title: section.title,
                            questions: section.questions.map((question, i) => {
                                return ({
                                    answers: question.answers,
                                    question: question.question,
                                    answer: "",
                                    order: i
                                })
                            })
                        })
                    })
                )
            } else {
                console.log("No such document!");
                setWrongSurvey(true)
                setLoading(false)
            }
        }).catch((error) => {
            console.log("Error getting document:", error);
            setLoading(false)
            setWrongSurvey(true)
        });
    }

    const editSurvey = (sectionIndex, sectionTitle, sectionAnswers) => {
        var newarr = answersData
        var isResponded = newarr.findIndex((section) => section.title === sectionTitle)

        if (isResponded === -1) {
            newarr.push({ title: sectionTitle, questions: sectionAnswers })
        } else {
            newarr[sectionIndex] = { title: sectionTitle, questions: sectionAnswers }
        }
        setAnswersData([...newarr])
    }

    const checkSurvey = (sub) => {
        console.log("asd")
        setLoading(true)
        firestore().collection("submissions").doc(sub).get().then((doc) => {
            if (doc.exists) {
                // console.log("Document data:", doc.data());
                // setData(doc.data())
                setLoading(false)
                console.log(doc.data().sections)
                setAnswersData(doc.data().sections)
                setUserReady(true)
            } else {
                console.log("No such document!");
                setWrongSurvey(true)
                setLoading(false)
            }
        }).catch((error) => {
            console.log("Error getting document:", error);
            setLoading(false)
            setWrongSurvey(true)
        });
    }

    const checkUserEmail = (user) => {
        if (hash === "#frommanager") {
            firestore().collection("submissions").where("user", "==", user).where("completed", "==", true).where("fromManager", "==", true).get().then((querySnapshot) => {
                if (querySnapshot.docs.length === 0) {
                    setUserReady(true)
                } else {
                    var docs = querySnapshot.docs.map((doc) => {
                        return ({ ...doc.data(), id: doc.id })
                    })
                    docs[0].completed && setReview(true)
                    setNewSurvey(false)
                    setAnswersData(docs[0].sections)
                    setIdResponse(docs[0].id)
                    setUserReady(true)
                }
            }).catch((error) => {
                console.log("Error getting document:", error);
                alert("Ha occurrido un error al intentar comenzar la evaluación, inténtelo más tarde")
            })
        } else if (hash === "#review") {
            firestore().collection("submissions").where("user", "==", user).get().then((querySnapshot) => {
                if (querySnapshot.docs.length === 0) {
                    setUserReady(true)
                } else {
                    var docs = querySnapshot.docs.map((doc) => {
                        return ({ ...doc.data(), id: doc.id })
                    })
                    docs[0].completed && setReview(true)
                    setNewSurvey(false)
                    setAnswersData(docs[0].sections)
                    setIdResponse(docs[0].id)
                    setUserReady(true)
                }
            }).catch((error) => {
                console.log("Error getting document:", error);
                alert("Ha occurrido un error al intentar comenzar la evaluación, inténtelo más tarde")
            })
        } else {
            firestore()
                .collection("submissions")
                .where("user", "==", user)
                .where("completed", "==", false)
                .where("fromManager", "==", false)
                .get({ source: 'server' })
                .then((querySnapshot) => {
                    // console.log(querySnapshot)
                    // console.log("devuelve algo")
                    if (querySnapshot.docs.length === 0) {
                        console.log("Sin info")
                        setUserReady(true)
                        setWrongSurvey(false)
                    } else {
                        var docs = querySnapshot.docs.map((doc) => {
                            return ({ ...doc.data(), id: doc.id })
                        })
                        docs[0].completed && setReview(true)
                        setNewSurvey(false)
                        setWrongSurvey(false)
                        setAnswersData(docs[0].sections)
                        setIdResponse(docs[0].id)
                        setUserReady(true)
                    }
                }).catch((error) => {
                    console.log("Error getting document:", error);
                    setWrongSurvey(true)
                    // alert("Ha occurrido un error al intentar comenzar la evaluación, inténtelo más tarde")
                })
        }
    }


    const submitUser = () => {
        if (user === "") {
            alert("Introduce el usuario")
        } else {
            checkUserEmail(user)
        }
    }


    const sendSurvey = (finish, index) => {

        setLoading(true)

        var processedSurvey = answersData.map((section) => {
            return ({
                ...section,
                questions: section.questions.sort((a, b) => a.order - b.order)
            })
        })

        const surveySubmission = {
            user: user,
            timestamp: firestore.FieldValue.serverTimestamp(),
            sections: processedSurvey,
            completed: finish ? true : false,
            corrected: fromManager && finish ? true : false,
            template: id,
            fromManager: fromManager,
            manager: manager
        }

        if (finish && fromManager) {

            firestore().collection("submissions").where("user", "==", user).where("fromManager", "==", false).get().then((querySnapshot) => {
                if (querySnapshot.docs.length === 0) {
                    // alert("No se puede corregir esta encuesta")
                    setWrongSurvey(true)
                    setLoading(false)
                } else {
                    var docs = querySnapshot.docs.map((doc) => {
                        return ({ ...doc.data(), id: doc.id })
                    })
                    firestore().collection("submissions").doc(docs[0].id).update({ corrected: true }).then(() => {
                        // console.log('hecho')
                        setWrongSurvey(false)
                        setLoading(false)
                        finish && history.push('/completed')
                    }
                    ).catch(
                        err => {
                            alert("Ha occurrido un error al intentar comenzar la evaluación, inténtelo más tarde");
                            console.log(err)
                            setWrongSurvey(true)
                            setLoading(false)
                        }
                    );
                }
            }).catch((error) => {
                // alert("Ha occurrido un error al intentar comenzar la evaluación, inténtelo más tarde")
                setWrongSurvey(true)
                setLoading(false)
                console.log("Error getting document:", error);

            })
        }

        if (newSurvey) {
            firestore().collection("submissions").add(surveySubmission).then((docRef) => {
                setNewSurvey(false)
                setIdResponse(docRef.id)
                setAnimation("fadeOut")
                setAllChecked(undefined)
                setWrongSurvey(false)
                setLoading(false)

                setTimeout(async () => {
                    window.scroll(0, 0)
                    await setCurrentSection(index)
                    await setAnimation("fadeIn")
                }, 750)

            }).catch(
                err => {
                    console.log(err)
                    setWrongSurvey(true)
                    setLoading(false)
                    alert("Ha occurrido un error al intentar comenzar la evaluación, inténtelo más tarde")
                }
            );


        } else {
            firestore().collection("submissions").doc(idResponse).update(surveySubmission)
                .then(() => {

                    finish && !fromManager && fetch(`https://europe-west1-solviasurvey.cloudfunctions.net/surveyCompleted?user=${user}`)

                    setAnimation("fadeOut")
                    setAllChecked(undefined)
                    setLoading(false)

                    finish && history.push('/completed')

                    !finish && setTimeout(async () => {
                        window.scroll(0, 0)
                        await setCurrentSection(index)
                        await setAnimation("fadeIn")
                    }, 750)

                }).catch(() => {
                    setLoading(false)
                    setWrongSurvey(true)
                });
        }


    }

    const checkSection = (i) => {
        let pass = answersData[i - 1].questions.find((question) => question.answer === "")
        if (pass !== undefined) {
            setAllChecked(false)
            setTimeout(async () => {
                setAllChecked(undefined)
            }, 5000)
        }
        return pass !== undefined ? false : true
    }


    const LoaderModal = () => {
        return (
            <Box style={{ position: "fixed", top: 0, left: 0, right: 0, bottom: 0, zIndex: 150 }} background="rgba(0,0,0,.7)" fill align="center" justify="center">
                <Loader
                    type="Puff"
                    color="white"
                    height={100}
                    width={100}
                />
            </Box>
        )
    }


    const moveSection = (index, save, finish) => {

        if (save === false || finish) {
            setAnimation("fadeOut")
            !review && save && sendSurvey(finish)
            setAllChecked(undefined)
            setTimeout(async () => {
                window.scroll(0, 0)
                await setCurrentSection(index)
                await setAnimation("fadeIn")
            }, 750)
        } else if (review) {
            setAnimation("fadeOut")
            setTimeout(async () => {
                window.scroll(0, 0)
                await setCurrentSection(index)
                await setAnimation("fadeIn")
            }, 750)
        } else if (checkSection(index) === true) {
            !review && save && sendSurvey(finish, index)
        }
    }

    if (wrongSurvey) {
        return (
            <Box style={{ minHeight: "100%" }} height="auto" align="center" background="black" justify="center">
                <Box width="large" gap="medium" elevation="large" background="white" justify="center" align="center" pad="medium" round="small">
                    <Box width="300px" height="300px">
                        <Image fit="contain" src={require("../assets/4zero4.png")} />
                    </Box>
                    <Box align="center" style={{ maxWidth: "1000px" }} gap="small">
                        <Heading margin="none" textAlign="center" level="2">Ops, parece que ha ocurrido un error</Heading>
                        <Text>Puede que este error ocurra debido a problemas de conexión por los firewall, por favor accede a esta encuesta desde un dispositivo móvil. Muchas gracias</Text>
                    </Box>
                    <Box width="medium" gap="medium">
                        <Button color="black" primary label="Reintentar" onClick={submitUser} />
                    </Box>
                </Box>
            </Box>
        )
    } else if (!userReady) {
        return (
            <Box style={{ minHeight: "100%" }} height="auto" align="center" background="black" justify="center">
                {loading && <LoaderModal />}
                <Box width="large" gap="medium" elevation="large" background="white" justify="center" align="center" pad="medium" round="small">
                    <Box width="250px" height="75px">
                        <Image fit="contain" src={require("../assets/solviablack.png")} />
                    </Box>
                    <Box align="center" style={{ maxWidth: "1000px" }} gap="small">
                        <Heading margin="none" textAlign="center" level="2">{data.title}</Heading>
                        {/* <Text>{data.description}</Text> */}
                        <Text style={{ maxWidth: "700px" }}>Somos conscientes que 2019 ha sido un año difícil. No por ello queremos dejar de realizar la evaluación del desempeño. Al contrario, creemos que es una buena oportunidad para compartir tus preocupaciones y plantear un futuro positivo en el que el desarrollo y crecimiento personal estén presentes.</Text>
                        <Text style={{ maxWidth: "700px" }}>En esta Evaluación se realiza la valoración de competencias que permiten realizar un análisis sobre los comportamientos esperados en un empleado de Solvia.</Text>
                        <Text style={{ maxWidth: "700px" }}>Para cada competencia hay 5 comportamientos. Es importante que reflexiones en cada punto, piensa en situaciones que confirmen el comportamiento seleccionado intentando realizar una evaluación lo más objetiva y justificable posible. Es necesario que justifiques tu decisión para cada competencia en el campo de "Comentarios".</Text>
                        <Text style={{ maxWidth: "700px" }} color="gray" size="small">Sólo se deberá elegir la opción N/D en el caso de que la competencia a valorar no aplique. Si no se han observado evidencias de ese comportamiento en todo el año 2019, se debe elegir 1, la opción de menor valoración.</Text>
                    </Box>
                    <Box width="medium" gap="medium">
                        <Text>{fromManager ? "Correo del Colaborador" : "Tu Correo"}</Text>
                        <TextInput value={user} onChange={ev => setUser(ev.target.value.toLowerCase().trim().replace(/\s/g, ""))} placeholder="Email" />
                        <Button color="black" primary label="Comenzar" onClick={submitUser} />
                    </Box>
                </Box>
            </Box>
        )
    } else {
        return (
            <Box align="center" background="#f3f3f3" justify="center">
                {loading && <LoaderModal />}
                <Box fill="horizontal" align="center">
                    <Box width="large" pad="large">
                        {data.sections && data.sections.map((section, i) => {
                            return (
                                i === currentSection &&
                                <Box key={i} animation={{ type: animation, duration: 600 }} fill="horizontal">

                                    <Section review={review} section={section} sectionAnswered={answersData[i]} sectionCallback={(cb) => editSurvey(i, section.title, cb)} />

                                    {allChecked === false && <Box animation={["fadeIn", "slideDown"]} background="status-critical" round="small" pad="medium" align="center" margin={{ top: "medium" }}>
                                        <Text color="white">Completa todas las preguntas antes de continuar</Text>
                                    </Box>}


                                    <Box direction="row" justify="between" margin={{ vertical: "medium" }}>
                                        {currentSection > 0 && <Box flex="grow" align="start">
                                            <Button color="black" label="Anterior" onClick={() => moveSection(i - 1, false, false)} />
                                        </Box>}
                                        {currentSection < data.sections.length - 1 && <Box flex="grow" align="end">
                                            <Button disabled={allChecked === false} primary color="black" label="Siguiente" onClick={() => moveSection(i + 1, true, false)} />
                                        </Box>}
                                        {!review && currentSection === data.sections.length - 1 && <Box lex="grow" align="end">
                                            <Button disabled={allChecked === false} primary color="black" label="Finalizar" onClick={() => { checkSection(i + 1) === true && setShowComplete(true) }} />
                                        </Box>}
                                    </Box>
                                </Box>
                            )
                        })}
                    </Box>
                </Box>
                {showComplete && (
                    <Layer
                        onEsc={() => setShowComplete(false)}
                        onClickOutside={() => setShowComplete(false)}
                    >
                        <Box pad="medium" gap="small" width="medium" radius="small">
                            <Heading margin="none" level="3">¿Está seguro que desea completar la encuesta?</Heading>
                            <Text>Una vez completada la encuesta no podrá hacer modificaciones.</Text>
                            <Box justify="between" margin={{ top: "medium" }} direction="row">
                                <Button label="Volver" color="black" onClick={() => setShowComplete(false)} />
                                <Button label="Finalizar" primary color="black" onClick={() => moveSection(0, true, true)} />
                            </Box>
                        </Box>
                    </Layer>
                )}
            </Box>
        )
    }
}

export default Survey;

