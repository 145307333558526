import React from 'react';
import { Box, Heading, Image } from 'grommet'


const SurveyCompleted = () => {
    return (
        <Box fill={true} align="center" justify="center">
            <Box width="large" height="medium" pad="medium" gap="medium" align="center" justify="center" round="medium" background="#f3f3f3">
                <Box width="250px" height="75px">
                    <Image fit="contain" src={require("../assets/solviablack.png")} />
                </Box>
                <Heading style={{maxWidth:"700px"}} level="2">Muchas gracias por completar el cuestionario</Heading>
            </Box>
        </Box>
    );
}

export default SurveyCompleted;