import React, { useState, useEffect } from 'react';

import { useHistory, useParams, useLocation } from 'react-router-dom'

import firebase from 'firebase'
import moment from 'moment'
import Papa from 'papaparse'

import { Box, Heading, Button, Select, Collapsible, Stack, Text, Chart } from "grommet"
import { Contract, Expand, InstallOption } from 'grommet-icons'


const Results = () => {

    const [data, setData] = useState([])
    const [survey, setSurvey] = useState([])
    const [viewData, setViewData] = useState("Todos")
    const [sectionOpen, setSectionOpen] = useState([true])
    const history = useHistory();
    const id = useParams()
    const search = useLocation().search
    const path = useLocation().pathname


    useEffect(() => {
        // console.log(history.location.pathname)
        let first = path.substring('/admin/results/'.length)
        let surveyid = first.substring(0, first.indexOf('/'))
        loadInitialData(surveyid)
        console.log(id.id)
    }, [])

    const loadInitialData = (surveyid) => {
        firebase.firestore().collection("surveys").doc(surveyid).get().then((doc) => {
            if (doc.exists) {
                console.log("Document data:", doc.data());
                setSurvey(doc.data())
            } else {
                // doc.data() will be undefined in this case
                console.log("No such document!");
            }
        }).catch((error) => {
            console.log("Error getting document:", error);
        })
        if (id.id === "all") {
            firebase.firestore().collection("submissions")
                .where("template", "==", surveyid)
                .where("completed", "==", true)
                .onSnapshot((querySnapshot) => {
                    return (
                        setData(querySnapshot.docs.map((doc, i) => {
                            return ({ ...doc.data() })
                        }))

                    )
                })
        } else {
            firebase.firestore().collection("submissions")
                .where("template", "==", surveyid)
                .where("manager", "==", id.id)
                .where("completed", "==", true)
                .onSnapshot((querySnapshot) => {
                    return (
                        setData(querySnapshot.docs.map((doc, i) => {
                            return ({ ...doc.data() })
                        }))

                    )
                })
        }

    }


    const exportResults = () => {
        // console.log(data)
        let headersQuestions = data[0].sections.flatMap((section) => section.questions.map((question) => question.question))
        // console.log(headersQuestions)
        let processData = data.map(survey => {
            let fromManager = survey.fromManager ? "Manager" : "Usuario"
            return ({
                "Usuario": survey.user,
                "Manager": survey.manager,
                "Realizada por Manager": fromManager,
                "Fecha": moment(survey.timestamp.toDate()).format('MMMM Do YYYY, h:mm a'),
                ...Object.assign(...survey.sections.map((section) => {
                    return (
                        Object.assign(...section.questions.map((question) => {
                            return ({
                                [question.question]: question.answer + 1
                            })
                        }))
                    )
                }))
            })
        })
        let config = {
            quoteChar: '"',
            delimiter: ";",
            header: true,
            columns: ["Usuario", "Manager", "Realizada por", "Fecha", ...headersQuestions] //or array of strings
        }
        let csv = Papa.unparse(processData, config)

        let blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

        if (navigator.msSaveBlob) {
            navigator.msSaveBlob(blob, "Encuestas");
        } else {
            const link = document.createElement("a");
            if (link.download !== undefined) {
                const url = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", "Encuestas");
                link.style.visibility = "hidden";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }

    }
    const toogleSectionOpen = (i) => {
        var newarr = sectionOpen
        newarr[i] = !newarr[i]
        setSectionOpen([...newarr])
    }

    const getOccurrence = (array, value) => {
        var count = 0;
        array.forEach((v) => (v === value && count++));
        return count;
    }


    return (
        <Box>
            <Box background="#f3f3f3" pad="medium">
                <Heading level="1">Resultados de {survey.title}</Heading>
                <Box direction="row" align="center" justify="between">
                    <Box>
                        <Heading margin="none" level="3">Total de encuestas contestadas: {data.length}</Heading>
                    </Box>
                    <Box direction="row" gap="small">
                        <Box justify="end">
                            <Button color="black" primary icon={<InstallOption />} label="Descargar Resultados" onClick={exportResults} />
                        </Box>
                        <Box>
                            <Text>Mostrar Resultados</Text>
                            <Select
                                options={['Todos', 'Manager', 'Colaborador']}
                                value={viewData}
                                onChange={({ option }) => setViewData(option)}
                            />
                        </Box>

                    </Box>

                </Box>
            </Box>
            <Box align="center" >
                {survey.sections && survey.sections.map((section, i) => {
                    let currentSection = i
                    return (
                        <Box overflow="hidden" key={i} width="xlarge" margin={{ vertical: "medium" }} background="white" round="small" elevation="medium">
                            <Box background="#f3f3f3" round={{"size": "small", "corner": "top"}} direction="row" fill="horizontal" justify="between" align="center" pad={{ horizontal: "small" }} onClick={() => toogleSectionOpen(i)}>
                                <Heading level="2" margin="none">{section.title}</Heading>
                                <Box margin="medium">
                                    {sectionOpen[i] ? <Contract /> : <Expand />}
                                </Box>
                            </Box>
                            <Collapsible direction="vertical" open={sectionOpen[i]}>
                            <Box pad="medium">
                                {section.questions.map((question, i) => {

                                    let currentQuestion = i
                                    let allresults = data.map((submission, i) => {
                                        return submission.sections[currentSection].questions[currentQuestion].answer
                                    })
                                    let managersResults = data.filter((submission) => submission.fromManager === true).map((submission, i) => {
                                        return submission.sections[currentSection].questions[currentQuestion].answer
                                    })
                                    let usersResults = data.filter((submission) => submission.fromManager === false).map((submission, i) => {
                                        return submission.sections[currentSection].questions[currentQuestion].answer
                                    })
                                    // console.log(allresults)

                                    return (
                                        <Box key={i} animation="fadeIn">
                                            <Box fill="horizontal">
                                                <Heading style={{ maxWidth: "100%" }} level="3">{question.question}</Heading>
                                            </Box>
                                            {question.type !== "Paragraph" &&
                                                <Box direction="row" pad="small" background="#f9f9f9" round="small">
                                                    {question.answers.map((answer, i) => {
                                                        return (
                                                            <Box key={i} fill align="center">
                                                                <Stack>
                                                                    {(viewData === "Todos" || viewData == "Colaborador") && <Chart
                                                                        bounds={[[0, 2], [0, data.length]]}
                                                                        values={[{ value: [1, getOccurrence(usersResults, i)] }]}
                                                                        type="bar"
                                                                        color={{ color: "black", opacity: viewData === "Todos" ? "medium" : "strong" }}
                                                                        aria-label="chart"
                                                                        thickness="200px"
                                                                        overflow
                                                                    // size={{ width: "full" }}
                                                                    />}
                                                                    {(viewData === "Todos" || viewData == "Manager") && <Chart
                                                                        bounds={[[0, 2], [0, data.length]]}
                                                                        values={[{ value: [1, getOccurrence(managersResults, i)] }]}
                                                                        type="bar"
                                                                        color={{ color: "#E5C956", opacity: viewData === "Todos" ? "medium" : "strong" }}
                                                                        aria-label="chart"
                                                                        thickness="200px"
                                                                        overflow
                                                                    // size={{ width: "full" }}
                                                                    />}
                                                                </Stack>
                                                                <Box align="center" margin="small">
                                                                    <Heading margin="none" level="3">{answer}</Heading>
                                                                    <Text>{getOccurrence(viewData === "Todos" ? allresults : viewData === "Manager" ? managersResults : managersResults, i)} Resp</Text>
                                                                </Box>
                                                            </Box>
                                                        )
                                                    })}
                                                </Box>
                                            }
                                            {question.type === "Paragraph" && <Box gap="medium" height={{ max: "medium" }} overflow="scroll">
                                                {data.map((submission, i) => {
                                                    if (viewData === "Todos") {
                                                        return (
                                                            <Box key={i} gap="small" height={{ min: "auto" }} pad="small" border={{ "color": submission.fromManager ? "#E5C956" : "border", "size": "4px", "style": "solid", "side": "left" }}>
                                                                <Text color="gray" size="small">{submission.user}</Text>
                                                                <Text>{submission.sections[currentSection].questions[currentQuestion].answer}</Text>
                                                            </Box>
                                                        )
                                                    } if (viewData === "Manager") {
                                                        if (submission.fromManager) {
                                                            return (
                                                                <Box key={i} gap="small" height={{ min: "auto" }} pad="small" border={{ "color": submission.fromManager ? "#E5C956" : "border", "size": "4px", "style": "solid", "side": "left" }}>
                                                                    <Text color="gray" size="small">{submission.user}</Text>
                                                                    <Text>{submission.sections[currentSection].questions[currentQuestion].answer}</Text>
                                                                </Box>
                                                            )
                                                        } else {
                                                            return null
                                                        }
                                                    } else {
                                                        if (!submission.fromManager) {
                                                            return (
                                                                <Box key={i} gap="small" height={{ min: "auto" }} pad="small" border={{ "color": submission.fromManager ? "#E5C956" : "border", "size": "4px", "style": "solid", "side": "left" }}>
                                                                    <Text color="gray" size="small">{submission.user}</Text>
                                                                    <Text>{submission.sections[currentSection].questions[currentQuestion].answer}</Text>
                                                                </Box>
                                                            )
                                                        } else {
                                                            return null
                                                        }
                                                    }
                                                })
                                                }
                                            </Box>
                                            }
                                        </Box>
                                    )
                                })}
                            </Box>
                            </Collapsible>
                        </Box>
                    )
                })}
            </Box>
        </Box>
    );
}

export default Results;