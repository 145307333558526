import React, { useEffect, useState } from 'react';

import { useHistory, useParams, useLocation } from 'react-router-dom'
import matchSorter from 'match-sorter'

import firebase from 'firebase'
import moment from 'moment'

import { Box, Layer, Heading, Text, Button, Select } from 'grommet'
import { View, InProgress, StatusGood, Trash, Download } from 'grommet-icons';
import Papa from 'papaparse'
import _ from "lodash"

import ReactTable from 'react-table'
import 'react-table/react-table.css'

const Submissions = () => {

    const [data, setData] = useState([])
    const [users, setUsers] = useState([])
    const [dataToDisplay, setDataToDisplay] = useState([])
    const [show, setShow] = useState(false)
    const [dataType, setDataType] = useState("Todos")
    const [surveyToDelete, setSurveyToDelete] = useState('')
    const [uno, setUno] = useState([])
    const history = useHistory();
    const id = useParams()
    const search = useLocation().search

    useEffect(() => {
        // console.log(id)
        firebase.firestore().collection("submissions").where("template", "==", id.id).where("completed", "==", true).onSnapshot((querySnapshot) => {
            setData(querySnapshot.docs.map((doc) => {
                return ({ ...doc.data(), id: doc.id })
            }))
            setDataToDisplay(querySnapshot.docs.map((doc) => {
                return ({ ...doc.data(), id: doc.id })
            }))
        })

        firebase.firestore().collection("users").onSnapshot((querySnapshot) => {
            // let groupedby = _.groupBy(querySnapshot.docs.map((doc, i) => {
            //     return ({ ...doc.data(), id: doc.id, index: i })
            // }), "manager")

            // firebase.firestore().collection("submissions").where("template", "==", id.id).where("fromManager", "==", true).where("completed", "==", true).onSnapshot((querySnapshot) => {
            //     let thing = querySnapshot.docs.map((doc) => {
            //         return ({ ...doc.data(), id: doc.id })
            //     })
            //     console.log(Object.keys(groupedby))
            //     let mas = Object.values(groupedby).map(el => el.map(user => {
            //         return ({
            //             ...user,
            //             encuesta: thing.filter(mo => mo.user === user.email)[0]
            //         })
            //     }))

            //     console.log(mas.map(manager => manager.filter(user => user.encuesta === undefined).map(user => user.manager)[0]).filter(el => el !== undefined))
            // })


            return (
                setUsers(querySnapshot.docs.map((doc, i) => {
                    return ({ ...doc.data(), id: doc.id, index: i })
                }))

            )
        })
    }, [])


    const managersIncompletos = () => {
        let groupedby = _.groupBy(users.map(user => user), "manager")

        firebase.firestore().collection("submissions").where("template", "==", id.id).where("fromManager", "==", true).where("completed", "==", true).onSnapshot((querySnapshot) => {
            let thing = querySnapshot.docs.map((doc) => {
                return ({ ...doc.data(), id: doc.id })
            })
            // console.log(Object.keys(groupedby))
            let mas = Object.values(groupedby).map(el => el.map(user => {
                return ({
                    ...user,
                    encuesta: thing.filter(mo => mo.user === user.email)[0]
                })
            }))

            exportManagers(mas.map(manager => manager.filter(user => user.encuesta === undefined).map(user => user.manager)[0]).filter(el => el !== undefined))
        })
    }

    // useEffect(() => {

    //     firebase.firestore().collection("submissions").where("template", "==", id.id).where("fromManager", "==", true).where("completed", "==", true).onSnapshot((querySnapshot) => {
    //         let thing = querySnapshot.docs.map((doc) => {
    //             return ({ ...doc.data(), id: doc.id })
    //         })
    //         console.log(thing)
    //     })

    // }, [])

    const updateManager = (userMail, submissionId) => {
        console.log(userMail, submissionId)
        console.log(users.find(el => el.email === userMail))
        var userSelected = users.find(el => el.email === userMail)
        userSelected !== undefined && firebase.firestore().collection("submissions").doc(submissionId).update({ manager: userSelected.manager }).then(() => {
            console.log("Bien")
        }).catch(err => console.log(err))
    }

    useEffect(() => {

        dataType === "Todos" && setDataToDisplay(data)
        dataType === "Manager" && setDataToDisplay(data.filter(el => el.fromManager))
        dataType === "Usuarios" && setDataToDisplay(data.filter(el => !el.fromManager))

    }, [dataType])


    const deleteSurvey = (surveyId) => {
        firebase.firestore().collection("submissions").doc(surveyId).delete().then(() => {
            console.log("Survey successfully deleted!");
            setShow(false)
            setSurveyToDelete('')
        }).catch((error) => {
            console.error("Error removing document: ", error);
            alert("Ha ocurrido un error, no se ha podido eliminar esta encuesta")
        });
    }

    const exportResults = () => {
        // console.log(data)
        // let headersQuestions = data[0].sections.flatMap((section) => section.questions.map((question) => question.question))
        // console.log(headersQuestions)
        let processData = data.filter(el => !el.fromManager).map(user => {
            return ({
                "Email": user.user,
            })
        })

        let config = {
            quoteChar: '"',
            delimiter: ";",
            header: true,
            columns: ["Email"] //or array of strings
        }
        let csv = Papa.unparse(processData, config)

        let blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

        if (navigator.msSaveBlob) {
            navigator.msSaveBlob(blob, "Incompletos");
        } else {
            const link = document.createElement("a");
            if (link.download !== undefined) {
                const url = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", "Encuestas-Completadas");
                link.style.visibility = "hidden";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }

    }


    const exportManagers = (dataManagers) => {
        // console.log(data)
        // let headersQuestions = data[0].sections.flatMap((section) => section.questions.map((question) => question.question))
        // console.log(headersQuestions)

        console.log(dataManagers)

        let processData = dataManagers.map(user => {
            return ({
                "Email": user,
            })
        })

        let config = {
            quoteChar: '"',
            delimiter: ";",
            header: true,
            columns: ["Email"] //or array of strings
        }
        let csv = Papa.unparse(processData, config)

        let blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

        if (navigator.msSaveBlob) {
            navigator.msSaveBlob(blob, "Incompletos");
        } else {
            const link = document.createElement("a");
            if (link.download !== undefined) {
                const url = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", "Managers Incompletos");
                link.style.visibility = "hidden";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }

    }


    return (
        <Box>
            <Box background="#f3f3f3" pad="medium" direction="row">
                <Box fill="horizontal">
                    <Heading level="2" margin="none">Encuestas Completadas ({dataToDisplay.length})</Heading>
                </Box>
                <Box direction="row" fill="horizontal" justify="end" align="end" gap="small">
                    <Select options={["Todos", "Usuarios", "Manager"]} value={dataType} onChange={(option) => setDataType(option.value)} />
                    {dataType !== "Manager" && <Button color="black" reverse label="Descargar Todos" icon={<Download />} onClick={() => exportResults()} />}
                    {dataType === "Manager" && <Button color="black" reverse label="Managers Incompletos" icon={<Download />} onClick={() => managersIncompletos()} />}
                </Box>
            </Box>
            <Box pad="medium">
                <ReactTable
                    data={dataToDisplay}
                    defaultPageSize={10}
                    columns={[
                        {
                            Header: 'User',
                            accessor: 'user', // String-based value accessors!
                            style: { display: "flex", paddingLeft: 15, paddingRight: 15, alignItems: "center" },
                            filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ["user"] }),
                            filterAll: true,
                            filterable: true
                        }, {
                            Header: 'Completada',
                            id: 'timestamp',
                            style: { display: "flex", paddingLeft: 15, paddingRight: 15, alignItems: "center" },
                            accessor: d => <Text>{moment(d.timestamp.toDate()).format('MMMM Do YYYY, h:mm a')}</Text>,

                        },
                        // {
                        //     Header: 'Evaluación Manager',
                        //     id: 'corr',
                        //     width: 200,
                        //     accessor: d => <Box fill={true} justify="center" align="center">{d.corrected ? <StatusGood color="#308630" /> : <InProgress color="gainsboro" />}</Box>
                        // }, 
                        // {
                        //     Header: 'UpdateManager',
                        //     id: 'upmanag',
                        //     width: 200,
                        //     accessor: d => <Box fill={true} onClick={() => updateManager(d.user, d.id)} justify="center" align="center"><InProgress color="gainsboro" /></Box>
                        // },
                        {
                            Header: 'Manager',
                            accessor: 'manager',
                            // width: 200,
                            // accessor: d => <Box fill={true} justify="center" align="center">{d.corrected ? <StatusGood color="#308630" /> : <InProgress color="gainsboro" />}</Box>
                        },
                        {
                            Header: "Ver",
                            id: "view",
                            width: 100,
                            accessor: d => <Box fill="horizontal" align="center"><Button icon={<View />}
                                onClick={() => history.push({
                                    pathname: `/survey/${d.template}`,
                                    search: `?user=${d.user}&submission=${d.id}`,
                                    hash: "#review"
                                })} /></Box>
                        }, {
                            Header: "Eliminar",
                            id: "delete",
                            width: 100,
                            accessor: d => <Box fill="horizontal" align="center"> <Button icon={<Trash />} onClick={() => { setShow(true); setSurveyToDelete(d.id) }} /></Box>
                        }
                    ]}
                />
            </Box>
            {show && (
                <Layer
                    onEsc={() => setShow(false)}
                    onClickOutside={() => setShow(false)}
                >
                    <Box width="medium" gap="medium" pad="medium">
                        <Heading margin="none" level="3">Eliminar Respuesta</Heading>
                        <Text>Está seguro que desea eliminar esta respuesta a la encuesta? Esta acción no puede deshacerse</Text>
                        <Box direction="row" justify="between">
                            <Button label="Cerrar" onClick={() => setShow(false)} />
                            <Button primary label="Eliminar" color="status-critical" onClick={() => deleteSurvey(surveyToDelete)} />
                        </Box>
                    </Box>
                </Layer>
            )}
        </Box>
    )
};

export default Submissions;