import React, { useEffect, useState } from 'react';

import { useHistory, useParams, useLocation } from 'react-router-dom'
import matchSorter from 'match-sorter'
import firebase from 'firebase'

import { Box, Layer, Heading, Select, CheckBox, Text, TextInput, Button } from 'grommet'
import { Edit, Send, AddCircle, Trash, View } from 'grommet-icons';

import ReactTable from 'react-table'
import 'react-table/react-table.css'

const Users = () => {

    const [data, setData] = useState([])
    const [show, setShow] = useState(false)
    const [showDelete, setShowDelete] = useState(false)
    const [showSend, setShowSend] = useState(false)
    const [userToEdit, setUserToEdit] = useState({})
    const [newUser, setNewUser] = useState(false)
    const [managers, setManagers] = useState(undefined)
    const [filteredManagers, setFilteredManagers] = useState(undefined)
    const [managerSelected, setManagerSelected] = useState("")
    const [checked, setChecked] = React.useState([]);
    const history = useHistory();
    const id = useParams()
    const search = useLocation().search


    useEffect(() => {
        checkUsers()
    }, [])

    const fetchManagers = (user) => {
        firebase.firestore().collection("users").where("isManager", "==", true).get().then((querySnapshot) => {
            if (querySnapshot.docs.length === 0) {
                console.log("Sin managers")
                var docs = []
            } else {
                var docs = querySnapshot.docs.map((doc) => {
                    return ({ email: doc.data().email, username:doc.data().username, id: doc.id })
                })
            }
            return (docs)
        }).then((res) => {
            var newarr = res
            setManagers(newarr.map((manager) => {return {lab: manager.email, val:manager.email, extra: manager.username}}))
            setFilteredManagers(newarr.map((manager) => {return {lab: manager.email, val:manager.email, extra: manager.username}}))
            if (user.manager !== undefined) {
                setManagerSelected(user.manager)
            }
            console.log("Manager: " + managerSelected)
        }).catch(function (error) {
            console.log("Error getting document:", error);
        });
    }

    const checkUsers = () => {
        firebase.firestore().collection("users").onSnapshot((querySnapshot) => {
            return (
                setData(querySnapshot.docs.map((doc, i) => {
                    return ({ ...doc.data(), id: doc.id, index: i })
                }))

            )
        })
    }


    const editUserModal = (user) => {
        setUserToEdit(user)
        fetchManagers(user)
        setShow(true)
    }

    const newUserModal = () => {
        setNewUser(true)
        fetchManagers()
        setShow(true)
    }

    const closeModal = () => {
        setShow(false);
        setManagerSelected("");
        setUserToEdit({
            username: "",
            manager: "",
            email: "",
            id: "",
            isManager: false
        });
        setNewUser(false)
    }

    const editUser = () => {

        // console.log(userToEdit)
        if (newUser) {

            fetch(`https://europe-west1-solviasurvey.cloudfunctions.net/createUser`, {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ ...userToEdit, managername: userToEdit.managername, isManager: userToEdit.isManager, roles: { superadmin: false, manager: userToEdit.isManager } })
            }).then((response) => { console.log(response.json()); closeModal() })
        } else {
            firebase.firestore().collection("users").doc(userToEdit.id).set({ ...userToEdit, roles: { superadmin: false, manager: userToEdit.isManager } }).then(() => {
                // console.log('actualizado')
                closeModal()
            }).catch(
                err => console.log(err)
            );
        }

    }

    const deleteUser = (userId) => {
        fetch(`https://europe-west1-solviasurvey.cloudfunctions.net/deleteUser?users=["${userId}"]`).then(res => console.log(res))
    }

    const massDelete = () => {
        checked.map((user) => deleteUser(user))
        setShowDelete(false)
    }

    const sendSurvey = (user, username) => {
        fetch(`https://europe-west1-solviasurvey.cloudfunctions.net/sendSurvey?user=${user}&username=${username}`).then(response => console.log(response))
    }

    const massSend = () => {
        let massemail = checked.map(user => data.find(u => u.id === user))

        massemail.map(user => sendSurvey(user.email, user.username))

        setShowSend(false)
    }

    const onCheck = (event, value) => {
        if (event.target.checked) {
            setChecked([...checked, value]);
        } else {
            setChecked(checked.filter(item => item !== value));
        }
    };

    const onCheckAll = event => setChecked(event.target.checked ? data.map(datum => datum.id) : []);

    const getTrProps = (state, rowInfo, instance) => {
        if (rowInfo) {
            return {
                style: {
                    borderLeftColor: rowInfo.original.isManager ? "#FF9800" : null,
                    borderLeftWidth: rowInfo.original.isManager ? 5 : null,
                    borderLeftStyle: rowInfo.original.isManager ? "solid" : null,
                }
            }
        }
        return {};
    }

    return (
        <Box>
            <Box background="#f3f3f3" direction="row" align="center" pad="medium">
                <Box >
                    <Heading margin="none" level="2">Usuarios</Heading>
                </Box>
                <Box direction="row" fill="horizontal" justify="end" align="end" gap="small">
                    <Button primary color="black" reverse label="Nuevo Usuario" icon={<AddCircle />} onClick={() => newUserModal()} />
                    {checked.length > 1 && <Button color="black" icon={<Trash />} label="Eliminar Usuarios" onClick={() => setShowDelete(true)} />}
                </Box>

            </Box>
            <Box pad="medium">
                <ReactTable
                    data={data}
                    defaultPageSize={10}
                    getTrProps={getTrProps}
                    columns={[
                        {
                            id: "checkbox",
                            width: 50,
                            accessor: datum => (
                                <Box fill justify="center" align="center">
                                    <CheckBox
                                        color="black"
                                        key={datum.id}
                                        checked={checked.indexOf(datum.id) !== -1}
                                        onChange={e => onCheck(e, datum.id)}
                                    /></Box>
                            ),
                            Header: (
                                <Box fill="horizontal" align="center"><CheckBox
                                    checked={checked.length === data.length}
                                    indeterminate={
                                        checked.length > 0 && checked.length < data.length
                                    }
                                    onChange={onCheckAll}
                                /></Box>
                            ),
                            sortable: false
                        }, {
                            Header: 'Usuario',
                            style: { display: "flex", paddingLeft: 15, paddingRight: 15, alignItems: "center" },
                            accessor: 'username',
                            filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ["username"] }),
                            filterAll: true,
                            filterable: true
                        }, {
                            Header: 'Email',
                            style: { display: "flex", paddingLeft: 15, paddingRight: 15, alignItems: "center" },
                            accessor: 'email',
                            filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ["email"] }),
                            filterAll: true,
                            filterable: true
                        }, {
                            Header: 'Unidad',
                            style: { display: "flex", paddingLeft: 15, paddingRight: 15, alignItems: "center" },
                            accessor: 'unit',
                            filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ["unit"] }),
                            filterAll: true,
                            filterable: true
                        }, {
                            Header: 'Manager',
                            style: { display: "flex", paddingLeft: 15, paddingRight: 15, alignItems: "center" },
                            accessor: 'managername',
                            filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ["managername"] }),
                            filterAll: true,
                            filterable: true
                        }, {
                            Header: "Ver Manager",
                            id: "viewManager",
                            width: 100,
                            sortable: false,
                            accessor: d => d.isManager && <Box fill="horizontal" align="center"> <Button icon={<View />} 
                            // onClick={() => deleteUser(d.id)} 
                            onClick={() => history.push({
                                pathname: `/admin/manager/${d.email}`,
                                search: `?survey=Pdk1EJNdIFxyNe9ddGmE`,
                            })}
                            /></Box>
                        }, {
                            Header: "Editar",
                            id: "edit",
                            sortable: false,
                            width: 100,
                            accessor: d => <Box fill="horizontal" align="center"><Button icon={<Edit />} onClick={() => editUserModal(d)} /></Box>
                        }, {
                            Header: "Eliminar",
                            id: "delete",
                            width: 100,
                            sortable: false,
                            accessor: d => <Box fill="horizontal" align="center"> <Button icon={<Trash />} onClick={() => deleteUser(d.id)} /></Box>
                        }
                    ]}
                />

                <Box direction="row" margin={{ vertical: "small" }} fill="horizontal" justify="end" align="end" gap="small">
                    <Button primary disabled color="black" reverse label="Carga Masiva" icon={<AddCircle />} />
                    {checked.length > 1 && <Button color="black" icon={<Send />} label="Notificar Usuarios" onClick={() => setShowSend(true)} />}
                    {checked.length > 1 && <Button icon={<Trash />} label="Eliminar Usuarios" onClick={() => setShowDelete(true)} />}
                </Box>
            </Box>

            {showDelete && (
                <Layer
                    onEsc={() => setShowDelete(false)}
                    onClickOutside={() => setShowDelete(false)}
                >
                    <Box gap="medium" width="medium" round="medium" pad="medium">
                        <Box gap="small">
                            <Heading margin="none" level="3">Estas seguro que deseas eliminar estos usuarios?</Heading>
                            <Text>Esta acción no puede deshacerse, asegurate que los usuarios seleccionados son los correctos.</Text>
                        </Box>
                        <Box direction="row" justify="between">
                            <Button color="black" label="Cancelar" onClick={() => setShowDelete(false)} />
                            <Button color="status-critical" primary icon={<Trash />} label="Eliminar Usuarios" onClick={massDelete} />
                        </Box>

                    </Box>
                </Layer>
            )}

            {showSend && (
                <Layer
                    onEsc={() => setShowSend(false)}
                    onClickOutside={() => setShowSend(false)}
                >
                    <Box gap="medium" width="medium" round="medium" pad="medium">
                        <Box gap="small">
                            <Heading margin="none" level="3">Estas seguro que deseas enviar una notificación a estos usuarios?</Heading>
                            <Text>Asegurate que los usuarios seleccionados son los correctos.</Text>
                        </Box>
                        <Box direction="row" justify="between">
                            <Button color="black" label="Cancelar" onClick={() => setShowSend(false)} />
                            <Button color="black" primary icon={<Send />} label="Notificar Usuarios" onClick={massSend} />
                        </Box>

                    </Box>
                </Layer>
            )}


            {show && (
                <Layer
                    onEsc={() => closeModal()}
                    onClickOutside={() => closeModal()}
                >
                    <Box gap="small" width="medium" round="small" pad="medium">
                        <Heading level="3">{newUser ? "Nuevo Usuario" : "Editar Usuario"}</Heading>
                        <TextInput placeholder="Nombre" value={userToEdit.username} onChange={(ev) => setUserToEdit({ ...userToEdit, username: ev.target.value })} />
                        <TextInput placeholder="Email" value={userToEdit.email} onChange={(ev) => setUserToEdit({ ...userToEdit, email: ev.target.value })} />
                        <TextInput placeholder="Unidad" value={userToEdit.unit} onChange={(ev) => setUserToEdit({ ...userToEdit, unit: ev.target.value })} />
                        <TextInput placeholder="Unidad Organizativa" value={userToEdit.team} onChange={(ev) => setUserToEdit({ ...userToEdit, team: ev.target.value })} />
                        <TextInput placeholder="Puesto" value={userToEdit.job} onChange={(ev) => setUserToEdit({ ...userToEdit, job: ev.target.value })} />
                        <Select
                            options={filteredManagers ? filteredManagers : []}
                            placeholder="Manager"
                            value={managerSelected}
                            labelKey={"lab"}
                            valueKey={"val"}
                            onChange={({ option }) => {
                                setManagerSelected(option.lab)
                                setUserToEdit({
                                    ...userToEdit,
                                    manager: option.val,
                                    managername: option.extra
                                })
                                console.log(option)
                            }}
                            onSearch={text => {
                                // The line below escapes regular expression special characters:
                                // [ \ ^ $ . | ? * + ( )
                                const escapedText = text.replace(/[-\\^$*+?.()|[\]{}]/g, "\\$&");

                                // Create the regular expression with modified value which
                                // handles escaping special characters. Without escaping special
                                // characters, errors will appear in the console
                                const exp = new RegExp(escapedText, "i");
                                setFilteredManagers(filteredManagers.filter(o => exp.test(o.lab)));
                            }}
                            onClose={() => setFilteredManagers(managers)}
                        />
                        <Box margin={{ vertical: "small" }}>
                            <CheckBox
                                checked={userToEdit.isManager}
                                label="Manager"
                                onChange={(event) => setUserToEdit({
                                    ...userToEdit,
                                    isManager: event.target.checked
                                })}
                            />
                        </Box>
                        <Box direction="row" justify="between">
                            <Button label="Cerrar" onClick={() => closeModal()} />
                            <Button label="Guardar" onClick={() => editUser()} />
                        </Box>
                    </Box>
                </Layer>
            )}
        </Box>
    )
};

export default Users;