import React, { useEffect, useState } from 'react';

import { useHistory, useParams, useLocation } from 'react-router-dom'

import firebase from 'firebase'
import moment from 'moment'

import { Box, Heading, Text, Button } from 'grommet'
import { Task, Send, View, StatusGood } from 'grommet-icons';

import ReactTable from 'react-table'
import 'react-table/react-table.css'


const Manager = () => {


    const [data, setData] = useState([])
    const history = useHistory();
    const location = useLocation();
    const id = useParams()
    const permissions = JSON.parse(localStorage.getItem("user"))


    useEffect(() => {
        // console.log("PERMISIONS: " , permissions.roles.superadmin)
        let manageremail = JSON.parse(localStorage.getItem("user")).email
        !permissions.roles.superadmin && id.id === undefined && history.push(`/admin/manager/${manageremail}?survey=Pdk1EJNdIFxyNe9ddGmE`)
        // console.log(location.pathname.split('/')[3])
        !permissions.roles.superadmin && manageremail !== undefined && checkUsers(manageremail)
        checkUsers(location.pathname.split('/')[3])
    }, [])

    const checkUsers = (manageremail) => {
        firebase.firestore().collection("users").where("manager", "==", manageremail).get().then((querySnapshot) => {
            if (querySnapshot.docs.length === 0) {
                setData([])
                console.log("Nada :(")
            } else {
                var docs = querySnapshot.docs.map((doc) => {
                    return ({
                        ...doc.data(),
                        userId: doc.id,
                    })
                })
                return (docs)
            }
        }).then((dats) => {
            dats.forEach((user, i) => getSurveys(user.email, i, dats))
        }).catch(function (error) {
            console.log("Error getting document:", error);
        });
    }

    const getSurveys = (userMail, index, data) => {
        // console.log(search.replace('?survey=', ''))
        //CAMBIAR LA PLANTILLA POR UNA DINAMICA
        firebase.firestore().collection("submissions").where("user", "==", userMail).where("template", "==", "Pdk1EJNdIFxyNe9ddGmE").get().then((querySnapshot) => {
            if (querySnapshot.docs.length === 0) {
                data[index].survey = undefined
                setData([...data])
            } else {
                data[index].surveys = querySnapshot.docs.map(survey => {
                    return ({
                        ...survey.data(),
                        id: survey.id
                    })
                })

                setData([...data])
            }
        }).catch(function (error) {
            console.log("Error getting document:", error);
        });
    }

    const sendSurvey = (user, username) => {
        alert("Se ha enviado un recordatorio al usuario")
        // fetch(`https://europe-west1-solviasurvey.cloudfunctions.net/sendSurvey?user=${user}&username=${username}`).then(response => console.log(response))
    }


    console.log(data)

    return (
        <Box>
            <Box background="#f3f3f3" pad="medium">
                <Heading margin="none" level="2">Mis Usuarios</Heading>
            </Box>
            <Box pad="medium">
                <ReactTable
                    data={data}
                    defaultPageSize={10}
                    columns={[
                        {
                            Header: 'Usuario',
                            style: { display: "flex", paddingLeft: 15, paddingRight: 15, alignItems: "center" },
                            accessor: 'username' // String-based value accessors!
                        }, {
                            Header: 'Email',
                            style: { display: "flex", paddingLeft: 15, paddingRight: 15, alignItems: "center" },
                            accessor: 'email'
                        }, 
                        // {
                        //     Header: 'Última Edición',
                        //     id: 'timestamp',
                        //     style: { display: "flex", paddingLeft: 15, paddingRight: 15, alignItems: "center" },
                        //     accessor: d => <Text> {d.survey && moment(d.survey.timestamp.toDate()).format('MMMM Do YYYY, h:mm a')}</Text>
                        // }, 
                        {
                            Header: "Encuesta del Usuario",
                            id: "view",
                            width: 180,
                            accessor: d => {
                                var surCom = d.surveys && d.surveys.filter(survey => !survey.fromManager)[0]
                                console.log(surCom)
                                return (
                                    <Box fill="horizontal" align="center">
                                        <Button disabled={surCom === undefined ? true : false}
                                            icon={surCom === undefined ?
                                                <View /> : surCom.completed ?
                                                    <StatusGood color="green" />
                                                    :
                                                    <View />
                                            } onClick={() => history.push({
                                                pathname: `/survey/${surCom.template}`,
                                                search: `?user=${d.email}&submission=${surCom.id}`,
                                                hash: "#review"
                                            })} />
                                    </Box>
                                )
                            }
                        }, {
                            Header: "Valoración de Manager",
                            id: "edit",
                            width: 200,
                            accessor: d => {
                                console.log(d)
                                var surCom = d.surveys && d.surveys.filter(survey => survey.fromManager)[0]
                                return (
                                    <Box fill="horizontal" align="center">
                                        <Button
                                            // disabled={surCom === undefined ? true : false}
                                            icon={surCom === undefined ?
                                                <Task /> : surCom.corrected ?
                                                    <StatusGood color="green" /> :
                                                    <Task />
                                            }
                                            onClick={() => history.push({
                                                // pathname: `/survey/${d.template}`,
                                                pathname: `/survey/Pdk1EJNdIFxyNe9ddGmE`,
                                                
                                                search: surCom === undefined ? `?user=${d.email}` : `?user=${d.email}&submission=${surCom.id}`,
                                                hash: surCom && surCom.corrected ? "#review" : "#frommanager"
                                            })}
                                        />
                                    </Box>
                                )
                            }
                        }, 
                        // {
                        //     Header: "Enviar Recordatorio",
                        //     id: "reminder",
                        //     width: 150,
                        //     accessor: d => <Box fill="horizontal" align="center">
                        //         <Button
                        //             disabled={d.survey === undefined ? false : d.survey.corrected}
                        //             icon={<Send />}
                        //             onClick={() => sendSurvey(d.email, d.username)} />
                        //     </Box>
                        // },
                    ]}
                />
            </Box>
        </Box>
    );
}

export default Manager;